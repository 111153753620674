import {useDispatch} from 'react-redux'

import {
  setLastUpdatedNow,
  setSelectedIncasation,
  setTableSettings,
} from '../../redux/slices/incasationSlice'
import {
  confirmIncasation,
  getIncasationByIdApi,
  getIncasations,
} from '../../redux/asyncServices/incasationService'

function useIncasationsService() {
  const dispatch = useDispatch()

  const incasationsLasUpdatedNow = () => dispatch(setLastUpdatedNow())

  const getIncasationsList = (params) => dispatch(getIncasations({params}))
  const getIncasationById = (id) => dispatch(getIncasationByIdApi({id}))

  const setIncasationsTableSettings = async (tableSettings = {}) => {
    dispatch(setTableSettings(tableSettings))
    incasationsLasUpdatedNow()
  }

  const setSelectedIncasationData = (selectedIncasation) =>
    dispatch(setSelectedIncasation(selectedIncasation))

  const handleConfirmIncasation = async (id) => {
    await dispatch(confirmIncasation({id})).unwrap()
    incasationsLasUpdatedNow()
  }

  return {
    getIncasationsList,
    setIncasationsTableSettings,
    getIncasationById,
    handleConfirmIncasation,
    setSelectedIncasationData,
  }
}

export default useIncasationsService
