import {useDispatch} from 'react-redux'

import {
  deleteProducts,
  getProduct,
  getProducts,
  getProductsBalance,
  setProduct,
  updateProduct,
} from '../../redux/asyncServices/productsService'
import {
  clearProductsList,
  clearProductsTableFilters,
  clearSemiFinishedTableFilters,
  productsReducerReset,
  setLastUpdated,
  setProductsTableSettings,
  setSelectedId,
  setSelectedProducts,
  setSemiFinishedTableSetting,
  setType,
} from '../../redux/slices/productsSlice'
import useCustomNavigate from '../../hooks/useCustomNavigate'

function useProducts() {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const productsLastUpdated = () => dispatch(setLastUpdated(Date.now()))

  const getProductsData = (params) => dispatch(getProducts({params}))

  const getProductById = ({id}) => dispatch(getProduct({id}))

  const selectProducts = (data) => dispatch(setSelectedProducts(data))

  const addProduct = async (data = {}) => {
    await dispatch(setProduct(data)).then((response) => {
      if (response.payload?.id) {
        const pageToNavigate =
          response.payload?.type === 'final' ? `/products` : '/semi-finished'
        navigate(pageToNavigate)
        productsLastUpdated()
      }
    })
  }

  const updateProductData = async (data) => {
    await dispatch(updateProduct(data)).then((response) => {
      if (response.payload?.id) {
        const pageToNavigate =
          response.payload?.type === 'final' ? `/products` : '/semi-finished'
        navigate(pageToNavigate)
        productsLastUpdated()
      }
    })
  }

  const deleteProductsData = async ({ids}) => {
    await dispatch(deleteProducts({ids}))
    productsLastUpdated()
  }

  const productsReset = () => dispatch(productsReducerReset())

  const productsClearData = () => dispatch(clearProductsList())

  const setSelectedIdProductsOrSemi = (id) => {
    dispatch(setSelectedId(id))
  }
  const setTypeProductsOrSemi = (id) => {
    dispatch(setType(id))
  }

  const getProductsBalanceData = (params) =>
    dispatch(getProductsBalance({params}))

  const setProductTableSettings = async (tableSettings = {}) => {
    await dispatch(setProductsTableSettings(tableSettings))
    productsLastUpdated()
  }
  const resetProductsTableSettings = async () => {
    dispatch(clearProductsTableFilters())
    productsLastUpdated()
  }

  const setSemiFinishedTableSettings = async (tableSettings = {}) => {
    await dispatch(setSemiFinishedTableSetting(tableSettings))
    productsLastUpdated()
  }
  const resetSemiFinishedTableSettings = async () => {
    dispatch(clearSemiFinishedTableFilters())
    productsLastUpdated()
  }

  return {
    getProductsData,
    getProductById,
    addProduct,
    updateProductData,
    selectProducts,
    deleteProductsData,
    productsReset,
    setSelectedIdProductsOrSemi,
    setTypeProductsOrSemi,
    productsClearData,
    setProductTableSettings,
    resetProductsTableSettings,
    setSemiFinishedTableSettings,
    resetSemiFinishedTableSettings,
    getProductsBalanceData,
  }
}

export default useProducts
