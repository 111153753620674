import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {CopyOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import {useDeferredValue, useEffect, useState} from 'react'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import {PageHeaderWithMobileMenu} from '../../../../components'
import {SearchOutlined} from '../../../../components/antd-components/icon'
import {ButtonDefault} from '../../../../components/antd-components/button'
import {
  createItemHeaderMenu,
  isIncludeSelectedFilter,
} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import useNormService from '../../../../services/norm/normService'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'
import notify from '../../../../components/antd-components/notification/notify'
import AddButton from '../../../../components/antd-components/main-buttons/add-button/AddButton'
import DeleteButton from '../../../../components/antd-components/main-buttons/delete-button/DeleteButton'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import {normTableSettings} from '../../../../redux/selectors/normSelector'

function NormHeader() {
  const {t} = useTranslation()
  const {exportExcel} = useCommon()
  const navigate = useCustomNavigate()
  const [value, setValue] = useState('')
  const deferedValue = useDeferredValue(value)

  const {normDelete, setNormTableSettings, copySelectedNorm} = useNormService()
  const selectedNorms = useSelector((state) => state.norm.selectedNormInTable)
  const norms = useSelector((state) => state.norm.norms)
  const tableSettings = useSelector(normTableSettings)
  const navigateAddNorm = () => {
    navigate('/norm/add')
  }

  const confirmDelete = () => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => normDelete({ids: selectedNorms}),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const copyNormsHandler = () => {
    if (selectedNorms?.length > 0) {
      const maxNormTitleLength = 245
      const selectedNormsId = selectedNorms
      const longNormTitleIdArray = []
      const selectedNormsData = []
      norms.forEach((norm) => {
        if (selectedNormsId.includes(norm.id)) {
          selectedNormsData.push(norm)
        }
      })
      selectedNormsData.forEach((selectedNorm) => {
        if (selectedNorm.title.length > maxNormTitleLength) {
          longNormTitleIdArray.push(selectedNorm.id)
        }
      })
      if (longNormTitleIdArray.length > 0) {
        notify({
          description: t(`Unable to copy norm due to very long name`, {
            ids: `#${longNormTitleIdArray.join(', #')}`,
          }),
          type: 'error',
        })
      } else {
        copySelectedNorm(selectedNormsId)
      }
    }
  }
  const confirmCopy = () => {
    showConfirm({
      title: t('Confirm copy'),
      okFunction: copyNormsHandler,
      icon: <ExclamationCircleOutlined />,
    })
  }

  useEffect(() => {
    setNormTableSettings({f_title: deferedValue})
  }, [deferedValue])

  const downloadButtonHandler = () => {
    exportExcel({tableName: 'store-norms', tableSettings})
  }

  const actionHeader = [
    <SearchAntdComponent
      key={1}
      value={value}
      onChangeText={setValue}
      suffix={<SearchOutlined />}
    />,
    <AddButton onClick={navigateAddNorm} />,
    <DeleteButton
      onClick={confirmDelete}
      disabled={isIncludeSelectedFilter(norms, selectedNorms)}
    />,
    <ButtonDefault
      key={4}
      disabled={isIncludeSelectedFilter(norms, selectedNorms)}
      onClick={confirmCopy}
      text={t('Copy')}
      style={{
        width: '100%',
      }}
      icon={<CopyOutlined />}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  const item = createItemHeaderMenu([...actionHeader])
  return (
    <PageHeaderWithMobileMenu title="Norm" extra={actionHeader} items={item} />
  )
}

export default NormHeader
