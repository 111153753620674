import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from '../../../../components/antd-components/icon'
import variables from '../../../../globalStyle/colors.scss'
import useDebounce from '../../../../hooks/useDebounce'

import UnitsForm from './UnitsForm'
import withAntdModal from '../../../../HOCs/withAntdModal'

import {
  createItemHeaderMenu,
  isIncludeSelectedFilter,
} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'
import {PageHeaderWithMobileMenu} from '../../../../components'
import DeleteButton from '../../../../components/antd-components/main-buttons/delete-button/DeleteButton'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import {unitsTableSettingsSelector} from '../../../../redux/selectors/unitSelector'

function UnitsHeader({setUnitTableSettings, deleteUnitsData}) {
  const {t} = useTranslation()

  const selectedUnits = useSelector((state) => state.units.selectedUnits)
  const units = useSelector((state) => state.units.units)
  const search = useSelector((state) => state.units.tableSettings.f_name)
  const tableSettings = useSelector(unitsTableSettingsSelector)
  const {exportExcel} = useCommon()
  const [searchValue, setSearchValue] = useState('')

  const AddUnitsFormWithModal = withAntdModal(UnitsForm, {
    okButtonText: t('Add'),
    footer: null,
    title: t('Add record'),
    icon: <PlusOutlined />,
    style: {
      textTransform: 'uppercase',
      width: '100%',
      color: variables.add,
    },
    text: t('To add a unit, please enter a name and click add.'),
  })
  const handleSubmitDelete = () => deleteUnitsData({ids: selectedUnits})

  const confirmDelete = () => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: handleSubmitDelete,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }
  const debouncedValue = useDebounce(searchValue, 500)

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `dictionary/units`,
      tableSettings,
    })
  }

  const unitsActionHeader = [
    <SearchAntdComponent
      key={1}
      value={searchValue}
      onChangeText={setSearchValue}
      suffix={<SearchOutlined />}
    />,
    <AddUnitsFormWithModal key={3} text={t('Add')} />,
    <DeleteButton
      disabled={isIncludeSelectedFilter(units, selectedUnits)}
      onClick={() => confirmDelete()}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  const item = createItemHeaderMenu(unitsActionHeader)

  useEffect(() => {
    const debouncedSearchValue = !debouncedValue ? null : debouncedValue
    if (search !== debouncedSearchValue) {
      setUnitTableSettings({f_name: debouncedSearchValue})
    }
  }, [debouncedValue])

  return (
    <PageHeaderWithMobileMenu
      extra={unitsActionHeader}
      title="Units"
      items={item}
    />
  )
}

export default UnitsHeader
