import {useDispatch} from 'react-redux'
import {t} from 'i18next'
import {
  deleteMovementItem,
  getMovement,
  getMovements,
  setMovement,
  updateMovement,
} from '../../redux/asyncServices/movementsService'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import {
  resetSelectedMovement,
  setLastUpdated,
  setSelectedMovement,
  setTableSettings,
} from '../../redux/slices/movementSlice'
import notify from '../../components/antd-components/notification/notify'

function useMovements() {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()
  const movementLastUpdated = () => dispatch(setLastUpdated(Date.now()))
  const getMovementsData = (params) => dispatch(getMovements({params}))
  const getMovementById = ({id}) => dispatch(getMovement({id}))
  const addMovement = async (data) => {
    await dispatch(setMovement(data)).then((response) => {
      if (response.payload) {
        navigate(`/movements`)
        movementLastUpdated()
      }
    })
  }

  const deleteMovement = (movementId) => {
    dispatch(deleteMovementItem(movementId)).then((response) => {
      if (response.payload) {
        notify({
          description: t('Movement delete successfully'),
          type: 'success',
        })
      }
    })
  }
  const updateMovementData = async (data) => {
    await dispatch(updateMovement(data)).then((response) => {
      if (response.payload) {
        navigate(`/movements`)
        movementLastUpdated()
      }
    })
  }
  const selectedMovement = (data) => dispatch(setSelectedMovement(data))

  const setMovementTableSettings = (tableSettings = {}) => {
    dispatch(setTableSettings({tableSettings}))
    movementLastUpdated()
  }

  const resetSelectedMovementData = () => {
    dispatch(resetSelectedMovement())
  }

  return {
    getMovementById,
    getMovementsData,
    selectedMovement,
    setMovementTableSettings,
    updateMovementData,
    addMovement,
    deleteMovement,
    resetSelectedMovementData,
  }
}

export default useMovements
