import {useSelector} from 'react-redux'
import {PageHeaderWithMobileMenu} from '../../../../../components'
import DownloadButton from '../../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../../services/commonService'
import {writeOffConfirmTableSettings} from '../../../../../redux/selectors/writeOffConfirmSelector'

function WriteOffHeader() {
  const {exportExcel} = useCommon()
  const tableSettings = useSelector(writeOffConfirmTableSettings)
  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `store/products/write-offs`,
      tableSettings,
    })
  }

  const actionWriteOffHeader = [
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  return (
    <PageHeaderWithMobileMenu
      title="Write-off for confirm"
      showHeaderMenu={false}
      extra={actionWriteOffHeader}
    />
  )
}

export default WriteOffHeader
