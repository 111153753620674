import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {createItemHeaderMenu} from '../../../../utils/helpers'
import {PageHeaderWithMobileMenu} from '../../../../components'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import AddButton from '../../../../components/antd-components/main-buttons/add-button/AddButton'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import {movementTableSettings} from '../../../../redux/selectors/movementSelector'

function MovementsHeader() {
  const {t} = useTranslation()
  const header = t('New movement')
  const navigate = useCustomNavigate()
  const {exportExcel} = useCommon()
  const tableSettings = useSelector(movementTableSettings)
  const navigateAddMovements = () => {
    navigate('/movements/add', {state: {id: null, header}})
  }
  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `store/products/move`,
      tableSettings,
    })
  }

  const actionMovementsHeader = [
    <AddButton onClick={navigateAddMovements} />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]

  const item = createItemHeaderMenu(actionMovementsHeader)
  return (
    <PageHeaderWithMobileMenu
      title="Movements"
      items={item}
      extra={actionMovementsHeader}
    />
  )
}

export default MovementsHeader
