import {createSlice} from '@reduxjs/toolkit'

import {
  deletePrices,
  getDetailInformationByPrice,
  getPrice,
  getPrices,
  setPrice,
  updatePrice,
} from '../asyncServices/priceService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  prices: [],
  totalCount: 0,
  selectedPrices: [],
  detailInformationBySelectedPrice: [],
  selectedPrice: null,
  tableSettings: {
    s_direction: 'ASC',
    f_categoryId: null,
    f_marginRequiredTo: null,
    f_marginRequiredFrom: null,
    f_priceMaxFrom: null,
    f_priceMaxTo: null,
    f_priceTo: null,
    f_priceFrom: null,
    s_field: 'name',
    f_name: null,
    pageSize: 100,
    page: 1,
  },
  lastUpdated: null,
  isLoading: null,
}

const priceSlice = createSlice({
  name: 'pricesSlice',
  initialState,
  reducers: {
    setSelectedPrices: (state, action) => {
      state.selectedPrices = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload.tableSettings,
      }
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPrices.pending, (state) => {
      state.isLoading = 'getPrices'
    })
    builder.addCase(getPrices.fulfilled, (state, action) => {
      state.prices = action.payload?.products
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getDetailInformationByPrice.pending, (state) => {
      state.isLoading = 'getDetailInformation'
    })
    builder.addCase(getDetailInformationByPrice.fulfilled, (state, action) => {
      state.detailInformationBySelectedPrice = action.payload
      state.isLoading = null
    })
    builder.addCase(getPrice.pending, (state) => {
      state.isLoading = 'getPrices'
    })
    builder.addCase(getPrice.fulfilled, (state, action) => {
      state.selectedPrice = action.payload
      state.isLoading = null
    })
    builder.addCase(setPrice.pending, (state) => {
      state.isLoading = 'setPrice'
    })
    builder.addCase(setPrice.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(updatePrice.pending, (state) => {
      state.isLoading = 'updatePrice'
    })
    builder.addCase(updatePrice.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(deletePrices.pending, (state) => {
      state.isLoading = 'deletePrices'
    })
    builder.addCase(deletePrices.fulfilled, (state, action) => {
      state.selectedPrices = action.payload.selectedPrices
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {setSelectedPrices, setTableSettings, setLastUpdated} =
  priceSlice.actions
export default priceSlice.reducer
