import {useDispatch} from 'react-redux'

import {
  getUsers,
  getUser,
  setUser,
  updateUser,
  deleteUsers,
} from '../../redux/asyncServices/usersService'
import {
  setSelectedUsers,
  setTableSettings,
  setLastUpdated,
} from '../../redux/slices/usersSlice'

function useUsers() {
  const dispatch = useDispatch()

  const usersLastUpdated = () => dispatch(setLastUpdated(Date.now()))

  const getUsersData = (params) => dispatch(getUsers({params}))

  const getUserById = ({id}) => dispatch(getUser({id}))

  const selectUsers = (data) => dispatch(setSelectedUsers(data))

  const addUser = async (data) => {
    await dispatch(setUser(data))
    usersLastUpdated()
  }

  const updateUserData = async (data) => {
    await dispatch(updateUser(data))
    usersLastUpdated()
  }

  const deleteUsersData = async ({ids}) => {
    await dispatch(deleteUsers({ids}))
    usersLastUpdated()
  }

  const setUserTableSettings = async (tableSettings = {}) => {
    await dispatch(setTableSettings(tableSettings))
    usersLastUpdated()
  }

  return {
    getUsersData,
    getUserById,
    addUser,
    updateUserData,
    selectUsers,
    deleteUsersData,
    setUserTableSettings,
  }
}

export default useUsers
