import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  deletePlanning,
  downloadExcel,
  getPlanningById,
  getPlannings,
  setPlanning,
  updatePlanning,
} from '../../redux/asyncServices/plunningService'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import {
  changeAmountStore,
  clearPlanningRecords,
  getRecordById,
  setDraftRawId,
  setSelectedNormForDelete,
  setTableSettings,
  setPlanningAddTableSettings,
  resetPlanningAddTableSettings,
} from '../../redux/slices/planningSlice'
import notify from '../../components/antd-components/notification/notify'

function usePlanningService() {
  const navigate = useCustomNavigate()
  const successAddNorm = (description) => {
    notify({
      description: t(description),
      type: 'success',
    })
    navigate(`/planning`)
  }

  const failedAddNorm = (description) => {
    notify({
      description: t(description),
      type: 'error',
    })
  }

  const dispatch = useDispatch()
  const {t} = useTranslation()
  const getPlanningData = (params) => dispatch(getPlannings({params}))
  const getPlanningDetail = (id) => dispatch(getPlanningById({id}))
  const addNewPlanning = (value) =>
    dispatch(setPlanning(value)).then((response) =>
      response.payload
        ? successAddNorm('Success add planning')
        : failedAddNorm('Error add planning')
    )
  const setRawId = ({id, rawId}) => dispatch(setDraftRawId({id, rawId}))
  const clearPlanningSelectedRecords = () => dispatch(clearPlanningRecords())
  const getRowBySelectedId = (id) => dispatch(getRecordById({id}))
  const changeAmountInput = ({id, storeId, value, name}) =>
    dispatch(changeAmountStore({id, storeId, value, name}))
  const setPlanningTableSettings = async (tableSettings = {}) => {
    await dispatch(setTableSettings(tableSettings))
  }

  const setAddPlanningTableSettings = async (tableSettings = {}) => {
    await dispatch(setPlanningAddTableSettings(tableSettings))
  }

  const resetAddPlanningTableSettings = async () => {
    await dispatch(resetPlanningAddTableSettings())
  }

  const planningDelete = ({ids}) => {
    dispatch(deletePlanning({ids}))
  }
  const setSelectedPlanning = (id) => {
    dispatch(setSelectedNormForDelete(id))
  }

  const updatePlanningData = async (data) => {
    dispatch(updatePlanning(data)).then((response) =>
      response.payload
        ? successAddNorm('Success update planning')
        : failedAddNorm('Error update planning')
    )
  }

  const getExcelReport = (id) => {
    dispatch(downloadExcel(id))
  }

  return {
    getPlanningData,
    setPlanningTableSettings,
    setAddPlanningTableSettings,
    resetAddPlanningTableSettings,
    updatePlanningData,
    setSelectedPlanning,
    planningDelete,
    getPlanningDetail,
    clearPlanningSelectedRecords,
    changeAmountInput,
    getRowBySelectedId,
    setRawId,
    addNewPlanning,
    getExcelReport,
  }
}

export default usePlanningService
