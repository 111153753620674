import {useDispatch, useSelector} from 'react-redux'

import {useTranslation} from 'react-i18next'
import {
  deleteStores,
  getAllStores,
  getStore,
  getStores,
  setStore,
  updateStore,
} from '../../redux/asyncServices/storeService'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import {
  clearSelectedProduct,
  setIsOpenEditWindow,
  setIsOpenModalWindow,
  setLastUpdated,
  setSelectedId,
  setSelectedStores,
  setTableSettings,
  storesReducerReset,
} from '../../redux/slices/storeSlice'
import notify from '../../components/antd-components/notification/notify'
import {allStoresSelector} from '../../redux/selectors/storeSelector'

function useStore() {
  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const successAddStore = (description) => {
    notify({
      description: t(description),
      type: 'success',
    })
    navigate('/stores')
  }

  const failedAddStore = (description) => {
    notify({
      description: t(description),
      type: 'error',
    })
  }

  const allStores = useSelector(allStoresSelector)
  const getAllStoresData = (checkAreFetched = true) => {
    if (!checkAreFetched || allStores.length === 0)
      return dispatch(getAllStores())

    return null
  }

  const dispatch = useDispatch()
  const storesLastUpdated = () => dispatch(setLastUpdated(Date.now()))

  const getStoresData = (params) => dispatch(getStores({params}))
  const getStoreById = ({id}) => dispatch(getStore({id}))
  const changeIsOpenModalWindow = (value) => {
    dispatch(setIsOpenModalWindow(value))
  }
  const changeIsOpenEditModalWindow = (value) => {
    dispatch(setIsOpenEditWindow(value))
  }
  const changeSelectedId = (value) => dispatch(setSelectedId(value))

  const addStore = async ({name}) => {
    await dispatch(setStore({name})).then((response) => {
      if (response.payload) {
        successAddStore('Success add store')
        changeIsOpenModalWindow(false)
      } else {
        failedAddStore('Error add store')
      }
    })
    storesLastUpdated()
  }
  const updateStoreData = async ({id, name}) => {
    await dispatch(updateStore({id, name})).then((response) => {
      if (response.payload) {
        successAddStore('Success update store')
        changeIsOpenEditModalWindow(false)
        dispatch(clearSelectedProduct())
        changeSelectedId(null)
      } else {
        failedAddStore('Error update store')
      }
    })
    storesLastUpdated()
  }
  const selectStores = (data) => dispatch(setSelectedStores(data))

  const deleteStoresData = async ({ids}) => {
    await dispatch(deleteStores({ids})).then((response) => {
      if (response.payload) {
        successAddStore('Success delete')
      } else {
        failedAddStore('Error delete')
      }
    })
    storesLastUpdated()
  }
  const setStoreTableSettings = (tableSettings = {}) => {
    dispatch(setTableSettings({tableSettings}))
    storesLastUpdated()
  }
  const storesReset = () => dispatch(storesReducerReset())

  return {
    getAllStoresData,
    getStoresData,
    getStoreById,
    addStore,
    updateStoreData,
    selectStores,
    deleteStoresData,
    setStoreTableSettings,
    storesReset,
    changeIsOpenModalWindow,
    changeIsOpenEditModalWindow,
    changeSelectedId,
  }
}

export default useStore
