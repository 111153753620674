import {createSlice} from '@reduxjs/toolkit'

import {
  deleteSuppliers,
  getSupplier,
  getSuppliers,
  setSupplier,
  updateSupplier,
} from '../asyncServices/supplierService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  suppliers: [],
  totalCount: 0,
  selectedSuppliers: [],
  selectedSupplier: null,
  newSupplier: null,
  tableSettings: {
    s_direction: 'ASC',
    s_field: 'name',
    f_name: null,
    f_telephone: null,
    f_address: null,
    pageSize: 100,
    page: 1,
  },
  lastUpdated: null,
  isLoading: null,
}

const supplierSlice = createSlice({
  name: 'suppliersSlice',
  initialState,
  reducers: {
    setSelectedSuppliers: (state, action) => {
      state.selectedSuppliers = action.payload
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    clearNewSupplier: (state, action) => {
      state.newSupplier = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
    suppliersReducerReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSuppliers.pending, (state) => {
      state.isLoading = 'getSuppliers'
    })
    builder.addCase(getSuppliers.fulfilled, (state, action) => {
      state.suppliers = action.payload.suppliers
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getSupplier.pending, (state) => {
      state.isLoading = 'getSupplier'
    })
    builder.addCase(getSupplier.fulfilled, (state, action) => {
      state.selectedSupplier = action.payload
      state.isLoading = null
    })
    builder.addCase(setSupplier.pending, (state) => {
      state.isLoading = 'setSupplier'
    })
    builder.addCase(setSupplier.fulfilled, (state, action) => {
      state.isLoading = null
      state.newSupplier = action.payload
    })
    builder.addCase(updateSupplier.pending, (state) => {
      state.isLoading = 'updateSupplier'
    })
    builder.addCase(updateSupplier.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(deleteSuppliers.pending, (state) => {
      state.isLoading = 'deleteSuppliers'
    })
    builder.addCase(deleteSuppliers.fulfilled, (state, action) => {
      state.selectedSuppliers = action.payload.selectedSuppliers
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setSelectedSuppliers,
  setTableSettings,
  setLastUpdated,
  suppliersReducerReset,
  unitsReducerReset,
  clearNewSupplier,
} = supplierSlice.actions
export default supplierSlice.reducer
