import {Button, Input} from 'antd'
import {useTranslation} from 'react-i18next'

function RangeInputComponent({
  setAmountFrom,
  setAmountTo,
  amountFrom,
  amountTo,
  onSubmitFilterAmount,
}) {
  const {t} = useTranslation()

  const handleReset = () => {
    setAmountTo(null)
    setAmountFrom(null)
  }

  return (
    <div
      style={{
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Input
        placeholder={t('From')}
        style={{borderRadius: 8, width: 180}}
        value={amountFrom}
        onChange={(e) => setAmountFrom(e.target.value || null)}
      />
      <Input
        style={{borderRadius: 8, marginTop: 10, width: 180}}
        value={amountTo}
        placeholder={t('To')}
        onChange={(e) => setAmountTo(e.target.value || null)}
      />
      <div
        style={{
          marginTop: 15,
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <Button type="text" onClick={() => handleReset()} size="small">
          {t('Clear')}
        </Button>
        <Button type="primary" onClick={onSubmitFilterAmount} size="small">
          Ok
        </Button>
      </div>
    </div>
  )
}

export default RangeInputComponent
