import {useSelector} from 'react-redux'
import {useState} from 'react'
import usePrice from './priceService'
import RangeInputComponent from '../../components/antd-components/app-table/RangeInputComponent'
import {FilterIcon} from '../../components/antd-components/app-table'

const usePriceTableFilterService = () => {
  const priceTableSettings = useSelector((state) => state.prices.tableSettings)
  const {setPriceTableSettings} = usePrice()

  const [priceToFilter, setPriceToFilter] = useState(
    priceTableSettings.f_priceTo
  )
  const [priceFromFilter, setPriceFromFilter] = useState(
    priceTableSettings.f_priceFrom
  )
  const [priceMaxToFilter, setPriceMaxToFilter] = useState(
    priceTableSettings.f_priceMaxTo
  )
  const [priceMaxFromFilter, setPriceMaxFromFilter] = useState(
    priceTableSettings.f_priceMaxFrom
  )
  const [marginToFilter, setMarginToFilter] = useState(
    priceTableSettings.f_marginRequiredTo
  )
  const [marginFromFilter, setMarginFromFilter] = useState(
    priceTableSettings.f_marginRequiredFrom
  )

  const onSubmitFilterPrice = (confirm) => {
    setPriceTableSettings({
      ...priceTableSettings,
      f_priceTo: priceToFilter,
      f_priceFrom: priceFromFilter,
    })
    confirm()
  }

  const onSubmitFilterPriceMax = (confirm) => {
    setPriceTableSettings({
      ...priceTableSettings,
      f_priceMaxTo: priceMaxToFilter,
      f_priceMaxFrom: priceMaxFromFilter,
    })
    confirm()
  }

  const onSubmitMarginFilter = (confirm) => {
    setPriceTableSettings({
      ...priceTableSettings,
      f_marginRequiredTo: marginToFilter,
      f_marginRequiredFrom: marginFromFilter,
    })
    confirm()
  }

  const filterPriceIcon = (
    <FilterIcon isFilterActive={priceToFilter || priceFromFilter} />
  )
  const filterPriceMaxIcon = (
    <FilterIcon isFilterActive={priceMaxToFilter || priceMaxFromFilter} />
  )
  const filterMarginIcon = (
    <FilterIcon isFilterActive={marginToFilter || marginFromFilter} />
  )

  const rangePrice = ({confirm}) => (
    <RangeInputComponent
      amountTo={priceToFilter}
      amountFrom={priceFromFilter}
      setAmountTo={setPriceToFilter}
      setAmountFrom={setPriceFromFilter}
      onSubmitFilterAmount={() => onSubmitFilterPrice(confirm)}
    />
  )

  const rangeMargin = ({confirm}) => (
    <RangeInputComponent
      amountTo={marginToFilter}
      amountFrom={marginFromFilter}
      setAmountTo={setMarginToFilter}
      setAmountFrom={setMarginFromFilter}
      onSubmitFilterAmount={() => onSubmitMarginFilter(confirm)}
    />
  )

  const rangePriceMax = ({confirm}) => (
    <RangeInputComponent
      amountTo={priceMaxToFilter}
      amountFrom={priceMaxFromFilter}
      setAmountTo={setPriceMaxToFilter}
      setAmountFrom={setPriceMaxFromFilter}
      onSubmitFilterAmount={() => onSubmitFilterPriceMax(confirm)}
    />
  )

  return {
    filterPriceMaxIcon,
    filterMarginIcon,
    filterPriceIcon,
    rangePriceMax,
    rangeMargin,
    rangePrice,
  }
}

export default usePriceTableFilterService
