import {createSlice} from '@reduxjs/toolkit'
import {
  getStoreBalance,
  getStoreBalanceByItemTypeThunk,
  updateStoreProducts,
} from '../asyncServices/storeBalanceService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  storesBalance: [],
  selectedStoreId: null,
  totalCount: 0,
  tableSettings: {
    f_productName: null,
    s_direction: 'ASC',
    s_field: 'name',
    f_storeId: null,
  },
  defaultTableSettings: {
    f_productName: null,
    s_direction: 'ASC',
    s_field: 'name',
    f_storeId: null,
  },
  lastUpdated: null,
  isLoading: null,
}

export const storeBalanceLoadingKeys = {
  getStoreBalance: 'getStoreBalance',
  updateStoreProducts: 'updateStoreProducts',
}

const storeBalanceSlice = createSlice({
  name: 'storesBalanceSlice',
  initialState,
  reducers: {
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload.tableSettings,
      }
    },

    setToDefaultTableSettings: (state) => {
      state.tableSettings = {
        ...state.defaultTableSettings,
      }
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    setSelectedStoreId: (state, action) => {
      state.selectedStoreId = action.payload
    },
    setStoreBalance: (state, action) => {
      state.storesBalance = action.payload
    },
    setIsStoreBalanceLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStoreBalance.pending, (state) => {
      state.isLoading = storeBalanceLoadingKeys.getStoreBalance
    })
    builder.addCase(getStoreBalance.fulfilled, (state, action) => {
      state.storesBalance = action.payload.balance
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getStoreBalanceByItemTypeThunk.pending, (state) => {
      state.isLoading = storeBalanceLoadingKeys.getStoreBalance
    })
    builder.addCase(
      getStoreBalanceByItemTypeThunk.fulfilled,
      (state, action) => {
        state.storesBalance = action.payload.balance
        state.totalCount = action.payload.totalCount
        state.isLoading = null
      }
    )
    builder.addCase(updateStoreProducts.pending, (state) => {
      state.isLoading = storeBalanceLoadingKeys.updateStoreProducts
    })
    builder.addCase(updateStoreProducts.fulfilled, (state, action) => {
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setTableSettings,
  setLastUpdated,
  setSelectedStoreId,
  setToDefaultTableSettings,
  setStoreBalance,
  setIsStoreBalanceLoading,
} = storeBalanceSlice.actions
export default storeBalanceSlice.reducer
