import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {ExclamationCircleOutlined} from '../../../../components/antd-components/icon'

import CategoryForm from './CategoryForm'
import CustomTreeView from '../categoryTreeView/CustomTreeView'
import SubCategoryForm from '../subCategory/SubCategoryForm'
import {createNestedItems, filterByNestedItems} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import {AntdTableActionModal} from '../../../../components/antd-components/app-table'

function CategoryBody({deleteCategoriesData, getCategoryById, form}) {
  const {t} = useTranslation()

  const categories = useSelector((state) => state.categories.categories)
  const isLoading = useSelector(
    (state) => state.categories.isLoading === 'getCategories'
  )

  const selectedCategory = useSelector(
    (state) => state.categories.selectedCategory
  )
  const valueSearch = useSelector((state) => state.categories.searchCategories)

  const [isEditDialogOpen, setEditDialogOpen] = useState(false)
  const [isAddSubCategory, setIsAddSubCategory] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  const categoryWithChild = createNestedItems(categories)
  const categoryData = filterByNestedItems(categoryWithChild, valueSearch)

  const onAddSubCategory = (id) => {
    setSelectedId(id)
    setIsAddSubCategory(true)
  }

  const handleAddSubCategoryClose = () => {
    setSelectedId(null)
    setIsAddSubCategory(false)
    form.resetFields(['name'])
  }

  const handleDeleteSubmit = (id) => {
    deleteCategoriesData({ids: [id]})
  }

  const onEdit = (id) => {
    setSelectedId(id)
    setEditDialogOpen(true)
  }

  const handleEditClose = () => {
    setSelectedId(null)
    setEditDialogOpen(false)
    form.resetFields()
  }

  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleDeleteSubmit(id),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  useEffect(() => {
    if (selectedCategory !== selectedId) {
      getCategoryById({id: selectedId})
    }
  }, [selectedId])

  return (
    <div style={{height: '75vh', width: '100%', overflow: 'auto'}}>
      <CustomTreeView
        data={categoryData}
        loading={isLoading}
        onEdit={onEdit}
        onDelete={confirmDelete}
        onAddSubCategory={onAddSubCategory}
      />

      {selectedCategory?.id && (
        <AntdTableActionModal
          open={isEditDialogOpen}
          title={t('Update category')}
          handleCancel={handleEditClose}
        >
          <CategoryForm
            onClose={handleEditClose}
            formData={selectedCategory}
            text={t('Update')}
            form={form}
          />
        </AntdTableActionModal>
      )}
      {selectedCategory?.id && (
        <AntdTableActionModal
          open={isAddSubCategory}
          title={t('Add subcategory')}
          handleCancel={handleAddSubCategoryClose}
        >
          <SubCategoryForm
            onClose={handleAddSubCategoryClose}
            form={form}
            formData={selectedCategory}
            text={t('Add')}
          />
        </AntdTableActionModal>
      )}
    </div>
  )
}

export default CategoryBody
