import {useDispatch} from 'react-redux'
import {
  getWriteOffById,
  getWriteOffReasonConfirm,
  writeOffConfirm,
  writeOffRejected,
} from '../../../redux/asyncServices/writeOffConfirm'
import {
  setLastUpdated,
  setSelectedWriteOff,
  setTableSettings,
} from '../../../redux/slices/writeOffConfirmSlice'
import useCommon from '../../commonService'

const useWriteOffConfirm = () => {
  const dispatch = useDispatch()
  const {changeOpenModalWindow} = useCommon()
  const writeOffReasonsConfirmLastUpdated = () =>
    dispatch(setLastUpdated(Date.now()))

  const confirmWriteOff = (value) => {
    dispatch(writeOffConfirm(value)).then(() => {
      changeOpenModalWindow(false)
      writeOffReasonsConfirmLastUpdated()
    })
  }

  const rejectWriteOff = (value) => {
    dispatch(writeOffRejected(value)).then(() => {
      changeOpenModalWindow(false)
      writeOffReasonsConfirmLastUpdated()
    })
  }

  const getWriteOffConfirm = (params) => {
    dispatch(getWriteOffReasonConfirm({params}))
  }

  const setWriteOffReasonConfirmTableSettings = async (tableSettings = {}) => {
    await dispatch(setTableSettings(tableSettings))
    writeOffReasonsConfirmLastUpdated()
  }

  const getSelectedWriteOff = async (id) => {
    await dispatch(getWriteOffById(id))
  }

  const setSelectedId = async (value) => {
    await dispatch(setSelectedWriteOff(value))
  }

  return {
    getWriteOffConfirm,
    confirmWriteOff,
    setWriteOffReasonConfirmTableSettings,
    writeOffReasonsConfirmLastUpdated,
    getSelectedWriteOff,
    setSelectedId,
    rejectWriteOff,
  }
}

export {useWriteOffConfirm}
