import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {PageHeaderWithMobileMenu} from '../../../../components'
import {inventoryTableSettings} from '../../../../redux/selectors/inventorySelector'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'

function InventoryHeader() {
  const {t} = useTranslation()
  const tableSettings = useSelector(inventoryTableSettings)
  const {exportExcel} = useCommon()

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `inventories`,
      tableSettings,
    })
  }

  const actionInventoryHeader = [
    <DownloadButton onClick={downloadButtonHandler} />,
  ]

  return (
    <div>
      <PageHeaderWithMobileMenu
        showHeaderMenu={false}
        title={t('Inventory')}
        extra={actionInventoryHeader}
      />
    </div>
  )
}

export default InventoryHeader
