import {createSlice} from '@reduxjs/toolkit'
import {
  deleteWriteOffReasons,
  getWriteOffReason,
  getWriteOffReasons,
  setWriteOffReason,
  updateWriteOffReason,
} from '../asyncServices/writeOfService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  writeOffReason: [],
  totalCount: 0,
  selectedWriteOffReasons: [],
  selectedWriteOffReason: null,
  tableSettings: {
    s_direction: 'ASC',
    s_field: 'name',
    f_name: null,
    pageSize: 100,
    page: 1,
  },
  lastUpdated: null,
  isLoading: null,
}

const WriteOffReasonSlice = createSlice({
  name: 'writeOfSlice',
  initialState,
  reducers: {
    setSelectedWriteOff: (state, action) => {
      state.selectedWriteOffReasons = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWriteOffReasons.pending, (state) => {
      state.isLoading = 'getWriteOffList'
    })
    builder.addCase(getWriteOffReasons.fulfilled, (state, action) => {
      state.writeOffReason = action.payload.woreasons
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getWriteOffReason.pending, (state) => {
      state.isLoading = 'getWriteOffReason'
    })
    builder.addCase(getWriteOffReason.fulfilled, (state, action) => {
      state.selectedWriteOffReason = action.payload
      state.isLoading = null
    })
    builder.addCase(setWriteOffReason.pending, (state) => {
      state.isLoading = 'setWriteOff'
    })
    builder.addCase(setWriteOffReason.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(updateWriteOffReason.pending, (state) => {
      state.isLoading = 'updateWriteOffReason'
    })
    builder.addCase(updateWriteOffReason.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(deleteWriteOffReasons.pending, (state) => {
      state.isLoading = 'deleteWriteOffReasons'
    })
    builder.addCase(deleteWriteOffReasons.fulfilled, (state, action) => {
      state.selectedWriteOffReasons = action.payload.selectedWriteOffReasons
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {setSelectedWriteOff, setTableSettings, setLastUpdated} =
  WriteOffReasonSlice.actions
export default WriteOffReasonSlice.reducer
