import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  ClearOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from '../../../../components/antd-components/icon'
import useDebounce from '../../../../hooks/useDebounce'

import {
  createItemHeaderMenu,
  isIncludeSelectedFilter,
} from '../../../../utils/helpers'
import {ButtonWithTooltip} from '../../../../components/antd-components/button'
import showConfirm from '../../../../components/PopupConfirm'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'
import {PageHeaderWithMobileMenu} from '../../../../components'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import AddButton from '../../../../components/antd-components/main-buttons/add-button/AddButton'
import DeleteButton from '../../../../components/antd-components/main-buttons/delete-button/DeleteButton'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'

function ProductsHeader({
  type,
  setProductTableSettings,
  deleteProductsData,
  productsTableSettings,
  tableSettingsReset,
  header,
  setTypeProductsOrSemi,
}) {
  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const {exportExcel} = useCommon()
  const selectedProducts = useSelector(
    (state) => state.products.selectedProducts
  )
  const products = useSelector((state) => state.products.products)
  const search = productsTableSettings.f_name
  const [searchValue, setSearchValue] = useState()

  const debouncedValue = useDebounce(searchValue, 500)

  useEffect(() => {
    setSearchValue(productsTableSettings.f_name)
  }, [productsTableSettings])

  const handleSubmitDelete = () => deleteProductsData({ids: selectedProducts})

  const onAddProduct = () => {
    setTypeProductsOrSemi(type)
    const navigateLink = type === 'semi' ? '/semi-finished/add' : '/product/add'
    navigate(navigateLink)
  }

  const clearFilters = () => {
    tableSettingsReset()
  }

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `calculation/products`,
      tableSettings: {
        ...productsTableSettings,
        calculation: true,
        f_type: [type],
      },
    })
  }

  const filterValue =
    productsTableSettings.f_previousAmount ||
    productsTableSettings.f_currentAmount ||
    productsTableSettings.f_unitId ||
    productsTableSettings.f_categoryId

  const clearFilter = [
    <ButtonWithTooltip
      key={6}
      title={t('Clear all filters')}
      style={{width: '100%', display: !filterValue ? 'flex' : 'block'}}
      disabled={!filterValue}
      onClick={() => clearFilters()}
    >
      <ClearOutlined />
    </ButtonWithTooltip>,
  ]

  const confirmDelete = () => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleSubmitDelete(),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const actionHeader = [
    <SearchAntdComponent
      key={1}
      value={searchValue}
      onChangeText={setSearchValue}
      suffix={<SearchOutlined />}
    />,
    <AddButton onClick={onAddProduct} />,
    <DeleteButton
      onClick={confirmDelete}
      disabled={isIncludeSelectedFilter(products, selectedProducts)}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  const item = createItemHeaderMenu([...actionHeader, ...clearFilter])

  useEffect(() => {
    const debouncedSearchValue = !debouncedValue ? null : debouncedValue
    if (search !== debouncedSearchValue) {
      setProductTableSettings({f_name: debouncedSearchValue})
    }
  }, [debouncedValue])

  return (
    <PageHeaderWithMobileMenu
      title={header}
      extra={
        filterValue
          ? [
              <ButtonWithTooltip
                key={5}
                type="link"
                title={t('Clear all filters')}
                onClick={() => clearFilters()}
              >
                <ClearOutlined />
              </ButtonWithTooltip>,
              ...actionHeader,
            ]
          : [...actionHeader]
      }
      items={item}
    />
  )
}

export default ProductsHeader
