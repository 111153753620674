import {useDispatch, useSelector} from 'react-redux'

import {
  getAccounting,
  setAccounting,
  updateAccounting,
} from '../../redux/asyncServices/accountingService'

import {
  initialState,
  resetAccountingShiftIdFilter,
  setActiveAccountingTargetId,
  setCompanyTableSettings,
  setLastUpdated,
  setStoreTableSettings,
} from '../../redux/slices/accountingSlice'
import {accountingTableSettingsSelector} from '../../redux/selectors/accountingSelector'

function useAccounting() {
  const dispatch = useDispatch()
  const {isStoreAccounting} = useSelector(accountingTableSettingsSelector)
  const accountingLastUpdated = () => dispatch(setLastUpdated(Date.now()))
  const getAccountingData = (params) => dispatch(getAccounting({params}))

  const setAccountingTableSettings = (tableSettings = {}) => {
    dispatch(
      isStoreAccounting
        ? setStoreTableSettings(tableSettings)
        : setCompanyTableSettings(tableSettings)
    )
    accountingLastUpdated()
  }

  const setDefaultStoreTableSettings = (storeId) => {
    dispatch(setStoreTableSettings({...initialState.tableSettings.store}))
    dispatch(setActiveAccountingTargetId(storeId))
    accountingLastUpdated()
  }

  const updateAccountingData = async (data) => {
    dispatch(updateAccounting(data)).then((response) => {
      if (response?.payload?.id) {
        accountingLastUpdated()
      }
    })
  }

  const addAccounting = async (data) => {
    dispatch(setAccounting(data)).then((response) => {
      if (response?.payload?.id) {
        accountingLastUpdated()
      }
    })
  }

  const handleSetActiveAccountingTargetId = (id) => {
    dispatch(setActiveAccountingTargetId(id))
    accountingLastUpdated()
  }

  const resetAccountingShiftId = () => dispatch(resetAccountingShiftIdFilter())

  return {
    getAccountingData,
    setAccountingTableSettings,
    setDefaultStoreTableSettings,
    updateAccountingData,
    addAccounting,
    handleSetActiveAccountingTargetId,
    resetAccountingShiftId,
  }
}

export default useAccounting
