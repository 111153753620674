import {useSelector} from 'react-redux'
import {PageHeaderWithMobileMenu} from '../../../../components'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import {purchasesTableSettingsSelector} from '../../../../redux/selectors/purchasesSelector'

function PurchaseHeader() {
  const {exportExcel} = useCommon()
  const tableSettings = useSelector(purchasesTableSettingsSelector)

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `purchase`,
      tableSettings,
    })
  }

  const actionPurchaseHeader = [
    <DownloadButton onClick={downloadButtonHandler} />,
  ]

  return (
    <PageHeaderWithMobileMenu
      title="Purchases"
      showHeaderMenu={false}
      extra={actionPurchaseHeader}
    />
  )
}

export default PurchaseHeader
