import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import {PageHeaderWithMobileMenu} from '../../../../components'
import {
  createItemHeaderMenu,
  isIncludeSelectedFilter,
} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import usePlanningService from '../../../../services/plunning/planningService'
import {
  planningData,
  planningTableSettings,
} from '../../../../redux/selectors/planningSelecor'
import AddButton from '../../../../components/antd-components/main-buttons/add-button/AddButton'
import DeleteButton from '../../../../components/antd-components/main-buttons/delete-button/DeleteButton'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'

function PlanningHeader() {
  const {t} = useTranslation()
  const {exportExcel} = useCommon()
  const navigate = useCustomNavigate()
  const header = 'Add planning'
  const {planningDelete} = usePlanningService()
  const tableSettings = useSelector(planningTableSettings)
  const selectedPlannings = useSelector(
    (state) => state.planning.selectedPlanningInTable
  )

  const plannings = useSelector(planningData)
  const navigateAddPlanning = () => {
    navigate('/planning/add', {state: {id: null, header}})
  }

  const confirmDelete = () => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => planningDelete({ids: selectedPlannings}),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const downloadButtonHandler = () => {
    exportExcel({tableName: 'plannings', tableSettings})
  }

  const actionHeader = [
    <AddButton onClick={navigateAddPlanning} />,
    <DeleteButton
      disabled={isIncludeSelectedFilter(plannings, selectedPlannings)}
      onClick={() => confirmDelete()}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]

  const item = createItemHeaderMenu([...actionHeader])
  return (
    <PageHeaderWithMobileMenu
      title="Planning"
      extra={actionHeader}
      items={item}
    />
  )
}

export default PlanningHeader
