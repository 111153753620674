import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {
  setLastUpdatedNow,
  setSelectedPreparationDepartment,
  setTableSettings,
} from '../../redux/slices/preparationDepartmentSlice'
import {
  addPreparationDepartmentThunk,
  deletePreparationDepartments,
  getPreparationDepartmentById as getPreparationDepartmentByIdRequest,
  getPreparationDepartmentsList,
  updatePreparationDepartmentThunk,
} from '../../redux/asyncServices/preparationDepartmentService'
import {preparationDepartmentsListSelector} from '../../redux/selectors/preparationDepartmentSelector'
import {
  setIsOpenEditWindow,
  setOpenModalWindow,
} from '../../redux/slices/commonSlice'
import notify from '../../components/antd-components/notification/notify'

function usePreparationDepartmentService() {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const failed = (description) => {
    notify({
      description: t(description),
      type: 'error',
    })
  }

  const preparationDepartmentUpdatedNow = () => dispatch(setLastUpdatedNow())

  const success = (description) => {
    notify({
      description: t(description),
      type: 'success',
    })
    preparationDepartmentUpdatedNow()
  }

  const getPreparationDepartments = (params) =>
    dispatch(getPreparationDepartmentsList({params}))
  const getPreparationDepartmentById = (id) =>
    dispatch(getPreparationDepartmentByIdRequest({id}))

  const changeIsOpenEditModalWindow = (value) => {
    dispatch(setIsOpenEditWindow(value))
  }
  const changeSelectedId = () => dispatch(setSelectedPreparationDepartment())

  const addPreparationDepartment = async (data) => {
    const response = await dispatch(
      addPreparationDepartmentThunk(data)
    ).unwrap()

    if (response) {
      success('Success add department')
      dispatch(setOpenModalWindow(false))
      preparationDepartmentUpdatedNow()
    } else {
      failed('Error add category')
    }
  }

  const updatePreparationDepartment = async (data) => {
    const response = await dispatch(
      updatePreparationDepartmentThunk(data)
    ).unwrap()

    if (response) {
      success('Success update department')
      changeIsOpenEditModalWindow(false)
      dispatch(setSelectedPreparationDepartment(null))
      changeSelectedId(null)
      preparationDepartmentUpdatedNow()
    } else {
      failed('Error update category')
    }
  }

  const setPreparationDepartmentsTableSettings = async (tableSettings = {}) => {
    dispatch(setTableSettings(tableSettings))
    preparationDepartmentUpdatedNow()
  }

  const setSelectedPreparationDepartmentData = (selectedList) =>
    dispatch(setSelectedPreparationDepartment(selectedList))

  const preparationDepartments = useSelector(preparationDepartmentsListSelector)

  const deletePreparationDepartmentsList = async (ids) => {
    await dispatch(deletePreparationDepartments({ids})).unwrap()
    getPreparationDepartments()
  }

  return {
    getPreparationDepartments,
    setPreparationDepartmentsTableSettings,
    setSelectedPreparationDepartmentData,
    getPreparationDepartmentById,
    addPreparationDepartment,
    updatePreparationDepartment,
    deletePreparationDepartmentsList,
    preparationDepartments,
  }
}

export default usePreparationDepartmentService
