import {createSlice} from '@reduxjs/toolkit'
import {
  getIncasationByIdApi,
  getIncasations,
} from '../asyncServices/incasationService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  incasations: [],
  totalCount: 0,
  tableSettings: {
    s_field: 'submissionDate',
    s_direction: 'DESC',
    page: 1,
    pageSize: 100,
    f_submissionDateTo: null,
    f_submissionDateFrom: null,
    f_confirmationDateTo: null,
    f_confirmationDateFrom: null,
    f_submittedById: null,
    f_status: null,
    f_storeId: null,
    f_shiftId: null,
    f_isCash: null,
  },
  lastUpdated: null,
  isLoading: null,
  selected: null,
}

export const loadingStateOptions = {
  getIncasations: 'getIncasations',
  getSelected: 'getSelected',
}

const incasationSlice = createSlice({
  name: 'incasationSlice',
  initialState,
  reducers: {
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
    setLastUpdatedNow: (state) => {
      state.lastUpdated = new Date().toISOString()
    },
    setSelectedIncasation: (state, action) => {
      state.selected = action.payload
    },
  },
  extraReducers: (builder) => {
    // list
    builder.addCase(getIncasations.pending, (state) => {
      state.isLoading = loadingStateOptions.getIncasations
    })
    builder.addCase(getIncasations.fulfilled, (state, action) => {
      state.incasations = action.payload.rows
      state.totalCount = action.payload.count
      state.isLoading = null
    })
    builder.addCase(getIncasations.rejected, (state) => {
      state.isLoading = null
    })

    // by id
    builder.addCase(getIncasationByIdApi.pending, (state) => {
      state.isLoading = loadingStateOptions.getSelected
    })
    builder.addCase(getIncasationByIdApi.fulfilled, (state, action) => {
      state.selected = action.payload
      state.isLoading = null
    })
    builder.addCase(getIncasationByIdApi.rejected, (state) => {
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setTableSettings,
  setLastUpdatedNow,
  clearInventoryHistory,
  setSelectedIncasation,
} = incasationSlice.actions
export default incasationSlice.reducer
