import {useState} from 'react'
import RangeInputComponent from '../../components/antd-components/app-table/RangeInputComponent'
import {FilterIcon} from '../../components/antd-components/app-table'

const useAmountRangeTableSetting = ({
  rangeFromAmountFilterName,
  rangeToAmountFilterName,
  tableSettings,
  setTableSettings,
}) => {
  const [amountFrom, setAmountFrom] = useState(
    tableSettings[rangeFromAmountFilterName]
  )
  const [amountTo, setAmountTo] = useState(
    tableSettings[rangeToAmountFilterName]
  )

  const onSubmitFilterAmount = (confirm) => {
    setTableSettings({
      ...tableSettings,
      [rangeToAmountFilterName]: amountTo,
      [rangeFromAmountFilterName]: amountFrom,
    })
    confirm()
  }

  const filterIcon = <FilterIcon isFilterActive={amountTo || amountFrom} />

  const filterDropdown = ({confirm}) => (
    <RangeInputComponent
      amountTo={amountTo}
      amountFrom={amountFrom}
      setAmountTo={setAmountTo}
      setAmountFrom={setAmountFrom}
      onSubmitFilterAmount={() => onSubmitFilterAmount(confirm)}
    />
  )

  return {
    filterIcon,
    filterDropdown,
  }
}

export default useAmountRangeTableSetting
