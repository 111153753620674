import {createSlice} from '@reduxjs/toolkit'
import {
  getWriteOffById,
  getWriteOffReasonConfirm,
  writeOffConfirm,
  writeOffRejected,
} from '../asyncServices/writeOffConfirm'
import {resetReduxStore} from '../resetStore'

const initialState = {
  writeOffReasonConfirm: [],
  totalCount: 0,
  selectedWriteOffReason: null,
  tableSettings: {
    s_direction: 'DESC',
    s_field: 'createdDate',
    f_name: null,
    pageSize: 100,
    f_fromStoreId: null,
    f_storeId: null,
    f_status: null,
    f_createdBy: null,
    f_updatedBy: null,
    f_createdDateFrom: null,
    f_createdDateTo: null,
    f_updatedDateFrom: null,
    f_updatedDateTo: null,
    page: 1,
  },
  lastUpdated: null,
  isLoading: null,
}

const WriteOffConfirmSlice = createSlice({
  name: 'writeOfConfirmSlice',
  initialState,
  reducers: {
    setSelectedWriteOff: (state, action) => {
      state.selectedWriteOffReason = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWriteOffReasonConfirm.pending, (state) => {
      state.isLoading = 'getWriteOffListConfirm'
    })
    builder.addCase(getWriteOffReasonConfirm.fulfilled, (state, action) => {
      state.writeOffReasonConfirm = action?.payload?.writeOffs
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getWriteOffById.pending, (state) => {
      state.isLoading = 'getWriteOffSelectedConfirm'
    })
    builder.addCase(getWriteOffById.fulfilled, (state, action) => {
      state.selectedWriteOffReason = action?.payload
      state.isLoading = null
    })
    builder.addCase(writeOffConfirm.pending, (state) => {
      state.isLoading = 'setWriteOffConfirm'
    })
    builder.addCase(writeOffConfirm.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(writeOffRejected.pending, (state) => {
      state.isLoading = 'setWriteOffRejected'
    })

    builder.addCase(writeOffRejected.fulfilled, (state, action) => {
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {setSelectedWriteOff, setTableSettings, setLastUpdated} =
  WriteOffConfirmSlice.actions
export default WriteOffConfirmSlice.reducer
