import {useDispatch} from 'react-redux'
import {
  getStoreBalance,
  getStoreBalanceByItemTypeThunk,
  updateStoreProducts,
} from '../../redux/asyncServices/storeBalanceService'
import useCustomNavigate from '../../hooks/useCustomNavigate'

import {
  setIsStoreBalanceLoading,
  setLastUpdated,
  setSelectedStoreId,
  setStoreBalance,
  setTableSettings,
  setToDefaultTableSettings,
} from '../../redux/slices/storeBalanceSlice'

function useStoreBalance() {
  const dispatch = useDispatch()
  const setLastUpdatedNow = () => dispatch(setLastUpdated(Date.now()))
  const getStoresBalance = (params) => dispatch(getStoreBalance({params}))
  const getStoreBalanceByItemType = (itemType, params) => {
    dispatch(getStoreBalanceByItemTypeThunk({itemType, params}))
    setLastUpdatedNow()
  }
  const setStoreBalanceData = (storeBalanceData) =>
    dispatch(setStoreBalance(storeBalanceData))
  const setIsBalanceLoading = (isLoadingKey) =>
    dispatch(setIsStoreBalanceLoading(isLoadingKey))
  const navigate = useCustomNavigate()

  const setStoreBalanceTableSettings = (tableSettings = {}) => {
    dispatch(setTableSettings({tableSettings}))
  }
  const updateStoreBalanceData = async (data) => {
    await dispatch(updateStoreProducts(data)).unwrap()
    setLastUpdatedNow()
    navigate('/storeBalance')
  }

  const setSelectedIdStore = (id) => {
    dispatch(setSelectedStoreId(id))
  }

  const resetToDefaultTableSettings = (id) => {
    dispatch(setToDefaultTableSettings())
  }

  const setStoreBalanceTableSettingsParams = (tableSettings = {}) => {
    dispatch(setTableSettings({tableSettings}))
  }

  return {
    getStoresBalance,
    getStoreBalanceByItemType,
    setStoreBalanceTableSettings,
    updateStoreBalanceData,
    setSelectedIdStore,
    setStoreBalanceData,
    setIsBalanceLoading,
    resetToDefaultTableSettings,
    setStoreBalanceTableSettingsParams,
  }
}

export default useStoreBalance
