import {useDispatch, useSelector} from 'react-redux'

import {getOrderById, getOrders} from '../../redux/asyncServices/ordersService'
import {orderTableSettingsSelector} from '../../redux/selectors/orderSelector'
import {setTableSettings} from '../../redux/slices/orderSlice'

function useOrdersService() {
  const dispatch = useDispatch()

  const tableSettings = useSelector(orderTableSettingsSelector)

  const getOrdersData = (params) => dispatch(getOrders({params}))
  const getOrderDetails = (id) => dispatch(getOrderById({id}))

  const fetchOrdersWithCurrentSettings = () => getOrdersData(tableSettings)

  const setOrdersTableSettings = async (
    newTableSettings,
    onTableSettingsChange
  ) => {
    const tableSettings = newTableSettings ?? {}
    dispatch(setTableSettings(tableSettings))
    await onTableSettingsChange?.(tableSettings)
  }

  const setOrdersTableSettingsAndRefetchList = async (tableSettings) =>
    setOrdersTableSettings(tableSettings, getOrdersData)

  return {
    getOrdersData,
    fetchOrdersWithCurrentSettings,
    setOrdersTableSettings,
    setOrdersTableSettingsAndRefetchList,
    getOrderDetails,
  }
}

export default useOrdersService
