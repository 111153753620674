import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {PageHeaderWithMobileMenu} from '../../../../components'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import {shiftsTableSettingsSelector} from '../../../../redux/selectors/shiftSelector'

function ShiftHeader() {
  const {t} = useTranslation()
  const {exportExcel} = useCommon()
  const tableSettings = useSelector(shiftsTableSettingsSelector)

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `shifts`,
      tableSettings,
    })
  }

  const actionShiftHeader = [<DownloadButton onClick={downloadButtonHandler} />]

  return (
    <div>
      <PageHeaderWithMobileMenu
        showHeaderMenu={false}
        title={t('Shift')}
        extra={actionShiftHeader}
      />
    </div>
  )
}

export default ShiftHeader
