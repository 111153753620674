import {createSlice} from '@reduxjs/toolkit'
import {
  deleteUsers,
  getUser,
  getUsers,
  setUser,
  updateUser,
} from '../asyncServices/usersService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  users: [],
  totalCount: 0,
  selectedUsers: [],
  selectedUser: null,
  tableSettings: {
    s_direction: 'ASC',
    s_field: 'name',
    f_name: null,
    f_type: null,
    f_active: [true],
    pageSize: 100,
    page: 1,
  },
  lastUpdated: null,
  isLoading: null,
}

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    setSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = 'getUsers'
    })
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload.users
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getUser.pending, (state) => {
      state.isLoading = 'getUser'
    })
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.selectedUser = action.payload
      state.isLoading = null
    })
    builder.addCase(setUser.pending, (state) => {
      state.isLoading = 'setUsers'
    })
    builder.addCase(setUser.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = 'updateUser'
    })
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(deleteUsers.pending, (state) => {
      state.isLoading = 'deleteUsers'
    })
    builder.addCase(deleteUsers.fulfilled, (state, action) => {
      state.selectedUsers = action.payload.selectedUsers
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {setSelectedUsers, setTableSettings, setLastUpdated} =
  usersSlice.actions
export default usersSlice.reducer
