import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import withAntdModal from '../../../../HOCs/withAntdModal'
import AccountingForm from './AccountingForm'
import {PlusOutlined} from '../../../../components/antd-components/icon'
import {createItemHeaderMenu} from '../../../../utils/helpers'
import variables from '../../../../globalStyle/colors.scss'
import {PageHeaderWithMobileMenu} from '../../../../components'
import AccountingSourceSelector from './AccountingSourceSelect'
import {accountingTableSettingsSelector} from '../../../../redux/selectors/accountingSelector'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'

function AccountingHeader({
  form,
  withAddBtn,
  handleSetActiveAccountingTargetId,
  isStoreAccounting,
}) {
  const {t} = useTranslation()
  const {exportExcel} = useCommon()
  const tableSettings = useSelector(accountingTableSettingsSelector)
  const AddStoreBalanceWithModal = withAntdModal(AccountingForm, {
    okButtonText: t('Add'),
    footer: null,
    title: t('Add record'),
    icon: <PlusOutlined />,
    style: {
      textTransform: 'uppercase',
      color: variables.add,
    },
  })

  const {activeAccountingTargetId} = useSelector(
    accountingTableSettingsSelector
  )

  const downloadButtonHandler = () => {
    if (isStoreAccounting) {
      exportExcel({
        tableName: `accounting/store`,
        tableSettings: tableSettings.store,
      })
    } else {
      exportExcel({
        tableName: `accounting/company`,
        tableSettings: tableSettings.company,
      })
    }
  }

  const accountingHeaderButton = withAddBtn
    ? [
        <AddStoreBalanceWithModal key={1} roles={['admin']} form={form} />,
        <DownloadButton onClick={downloadButtonHandler} />,
      ]
    : [<DownloadButton onClick={downloadButtonHandler} />]

  const item = createItemHeaderMenu(accountingHeaderButton)
  return (
    <PageHeaderWithMobileMenu
      title={t('Accounting')}
      subTitle={
        <AccountingSourceSelector
          activeAccountingTargetId={activeAccountingTargetId}
          setActiveAccountingTargetId={handleSetActiveAccountingTargetId}
        />
      }
      items={item}
      extra={accountingHeaderButton}
      // hiddenExtra={!withAddBtn}
    />
  )
}

export default AccountingHeader
