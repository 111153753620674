import {createSlice, current} from '@reduxjs/toolkit'
import {
  deleteAccountingCategory,
  getAccountingCategory,
  getAccountingCategoryById,
  setAccountingCategory,
  updateAccountingCategory,
} from '../asyncServices/accountingCategoryService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  lastUpdated: null,
  list: [],
  selectedAccountingCategories: [],
  selectedAccountingCategory: null,
  tableSettings: {
    s_direction: 'DESC',
    pageSize: 100,
    page: 1,
    s_field: 'createdDate',
    f_title: null,
    f_credit: null,
  },
  isLoading: null,
  totalCount: 0,
}

const accountingCategorySlice = createSlice({
  name: 'accountingCategorySlice',
  initialState,
  reducers: {
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload.tableSettings,
      }
    },
    clearSelectedProduct: (state, action) => {
      state.selectedAccountingCategory = null
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload
    },
    setSelectedAccountingCategories: (state, action) => {
      state.selectedAccountingCategories = action.payload
    },
    clearAccountingCategory: (state, action) => {
      state.list = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountingCategory.pending, (state) => {
      state.isLoading = 'getAccountingCategory'
    })
    builder.addCase(getAccountingCategory.fulfilled, (state, action) => {
      state.list = action.payload.accountings
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(updateAccountingCategory.pending, (state) => {
      state.isLoading = 'updateAccountingCategory'
    })
    builder.addCase(updateAccountingCategory.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(setAccountingCategory.pending, (state) => {
      state.isLoading = 'setAccountingCategory'
    })
    builder.addCase(setAccountingCategory.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(getAccountingCategoryById.pending, (state) => {
      state.isLoading = 'getAccountingCategoryById'
    })
    builder.addCase(getAccountingCategoryById.fulfilled, (state, action) => {
      state.selectedAccountingCategory = action.payload
      state.isLoading = null
    })

    builder.addCase(deleteAccountingCategory.pending, (state) => {
      state.isLoading = 'deleteAccountingCategory'
    })
    builder.addCase(deleteAccountingCategory.fulfilled, (state, action) => {
      state.selectedAccountingCategories =
        action.payload.selectedAccountingCategories
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setLastUpdated,
  setTableSettings,
  clearSelectedProduct,
  setSelectedId,
  setSelectedAccountingCategories,
  clearAccountingCategory,
} = accountingCategorySlice.actions
export default accountingCategorySlice.reducer
