import {combineReducers} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {LOG_OUT} from '../constants'
import authReducer from './slices/authSlice'
import commonReducer from './slices/commonSlice'
import unitsReducer from './slices/unitsSlice'
import productsReducer from './slices/productsSlice'
import writeOffReducer from './slices/writeOffReasonSlice'
import usersReducer from './slices/usersSlice'
import rawMaterialsReducer from './slices/rawMaterialsSlice'
import storesReducer from './slices/storeSlice'
import supplierSlice from './slices/supplierSlice'
import notificationSlice from './slices/notificationSlice'
import priceSlice from './slices/priceSlice'
import categorySlice from './slices/categorySlice'
import suppliesSlice from './slices/suppliesSlice'
import storesBalanceSlice from './slices/storeBalanceSlice'
import notificationMessageSlice from './slices/notificationMessageSlice'
import accountingSlice from './slices/accountingSlice'
import refillSlice from './slices/refillSlice'
import movementSlice from './slices/movementSlice'
import writeOffConfirm from './slices/writeOffConfirmSlice'
import purchaseSlice from './slices/purchaseSlice'
import inventorySlice from './slices/inventorySlice'
import normSlice from './slices/normSlice'
import planningSlice from './slices/planningSlice'
import accountingCategorySlice from './slices/accountingCategorySlice'
import tasksSlice from './slices/tasksSlice'
import suppliesHistoryByIngredientSlice from './slices/suppliesHistoryByIngredientSlice'
import incasationSlice from './slices/incasationSlice'
import shiftSlice from './slices/shiftSlice'
import preparationDepartmentSlice from './slices/preparationDepartmentSlice'
import orderReducer from './slices/orderSlice'

export const resetPersistedReducer = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('persist:')) {
      localStorage.removeItem(key)
    }
  })
}

const persistWithWhiteList = ({
  key,
  whitelist = ['tableSettings', 'treeSettings', 'selectedId'],
}) => ({
  key,
  version: 1,
  storage,
  whitelist,
})

const rootPersistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    'common',
    'products',
    'units',
    'users',
    'rawMaterials',
    'writeOff',
    'stores',
    'suppliers',
    'notification',
    'categories',
    'supplies',
    'prices',
    'storesBalances',
    'messageNotifications',
    'refills',
    'movements',
    'accounting',
    'writeOffConfirm',
    'purchaseSlice',
    'inventory',
    'incasation',
    'shifts',
    'norm',
    'planning',
    'accountingCategorySlice',
    'task',
    'historySuppliesByIngredient',
    'preparationDepartment',
    'orderSlice',
  ],
}

const appReducer = combineReducers({
  auth: authReducer,
  common: persistReducer(
    persistWithWhiteList({
      key: 'common',
      whitelist: ['language', 'isSideBarOpen'],
    }),
    commonReducer
  ),
  stores: persistReducer(persistWithWhiteList({key: 'stores'}), storesReducer),
  inventory: persistReducer(
    persistWithWhiteList({key: 'inventory'}),
    inventorySlice
  ),
  incasation: persistReducer(
    persistWithWhiteList({key: 'incasation'}),
    incasationSlice
  ),
  shifts: persistReducer(persistWithWhiteList({key: 'shifts'}), shiftSlice),
  units: persistReducer(persistWithWhiteList({key: 'units'}), unitsReducer),
  purchase: persistReducer(
    persistWithWhiteList({key: 'purchase'}),
    purchaseSlice
  ),
  orders: persistReducer(persistWithWhiteList({key: 'orders'}), orderReducer),
  products: persistReducer(
    persistWithWhiteList({key: 'products'}),
    productsReducer
  ),
  users: persistReducer(persistWithWhiteList({key: 'users'}), usersReducer),
  rawMaterials: persistReducer(
    persistWithWhiteList({key: 'rawMaterials'}),
    rawMaterialsReducer
  ),
  writeOff: persistReducer(
    persistWithWhiteList({key: 'writeOff'}),
    writeOffReducer
  ),
  suppliers: persistReducer(
    persistWithWhiteList({key: 'suppliers'}),
    supplierSlice
  ),
  categories: persistReducer(
    persistWithWhiteList({key: 'categories'}),
    categorySlice
  ),
  preparationDepartment: persistReducer(
    persistWithWhiteList({key: 'categories'}),
    preparationDepartmentSlice
  ),
  supplies: persistReducer(
    persistWithWhiteList({key: 'supplies'}),
    suppliesSlice
  ),
  prices: persistReducer(persistWithWhiteList({key: 'prices'}), priceSlice),
  storesBalances: persistReducer(
    persistWithWhiteList({
      key: 'storesBalances',
    }),
    storesBalanceSlice
  ),
  messageNotifications: persistReducer(
    persistWithWhiteList({key: 'messageNotifications'}),
    notificationMessageSlice
  ),
  accounting: persistReducer(
    persistWithWhiteList({key: 'accounting'}),
    accountingSlice
  ),
  refills: persistReducer(persistWithWhiteList({key: 'refills'}), refillSlice),
  movements: persistReducer(
    persistWithWhiteList({key: 'movements'}),
    movementSlice
  ),
  writeOffConfirm: persistReducer(
    persistWithWhiteList({key: 'writeOffConfirm'}),
    writeOffConfirm
  ),
  task: persistReducer(persistWithWhiteList({key: 'task'}), tasksSlice),

  norm: persistReducer(persistWithWhiteList({key: 'norm'}), normSlice),
  accountingCategory: persistReducer(
    persistWithWhiteList({key: 'accountingCategory'}),
    accountingCategorySlice
  ),
  planning: persistReducer(
    persistWithWhiteList({key: 'planning'}),
    planningSlice
  ),
  historySuppliesByIngredient: persistReducer(
    persistWithWhiteList({key: 'historySuppliesByIngredient'}),
    suppliesHistoryByIngredientSlice
  ),

  notification: notificationSlice,
})

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    const {common} = state
    state = {common}
    resetPersistedReducer()
    storage.removeItem('persist:root')
    return appReducer(state, action)
  }
  return appReducer(state, action)
}

export {rootPersistConfig, rootReducer}
