import {createSlice} from '@reduxjs/toolkit'
import {getRandomId, restrictInputValueAfterDot} from '../../utils/helpers'
import {
  deletePlanning,
  getPlanningById,
  getPlannings,
  setPlanning,
  updatePlanning,
} from '../asyncServices/plunningService'
import {getNormById} from '../asyncServices/normService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  plannings: [],
  createdPlanning: [
    {
      rowId: getRandomId(),
      normTitle: '',
      products: [],
    },
  ],
  selectedPlanning: null,
  totalCount: 0,
  selectedRow: null,
  selectedPlanningInTable: [],
  tableSettings: {
    s_direction: 'DESC',
    page: 1,
    pageSize: 100,
    f_createdDateFrom: null,
    f_createdDateTo: null,
    s_field: 'createdDate',
  },
  addPlanningTableSettings: {
    f_name: null,
  },
  normSettings: {
    withStoreBalances: true,
  },
  lastUpdated: null,
  isLoading: null,
}

const planningSlice = createSlice({
  name: 'planningSlice',
  initialState,
  reducers: {
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
      state.lastUpdated = Date.now()
    },
    setPlanningAddTableSettings: (state, action) => {
      state.addPlanningTableSettings = {
        ...state.addPlanningTableSettings,
        ...action.payload,
      }
      state.lastUpdated = Date.now()
    },
    resetPlanningAddTableSettings: (state) => {
      state.addPlanningTableSettings = initialState.addPlanningTableSettings
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    setDraftNorms: (state, action) => {
      state.createdPlanning = action.payload
    },
    setDraftRecords: (state, action) => {
      state.createdPlanning[0].products.push(action.payload)
    },
    setSelectedNormForDelete: (state, action) => {
      state.selectedPlanningInTable = action.payload
    },
    clearPlanningRecords: (state, action) => {
      state.selectedPlanning = []
      state.createdPlanning = [
        {
          productId: getRandomId(),
          normTitle: '',
          products: [],
        },
      ]
    },
    setDraftRawId: (state, action) => {
      const changedProductId = state.createdPlanning[0].products?.map(
        (item) => {
          const {id, rawId} = action.payload

          if (item.productId === id) {
            return {
              ...item,
              productId: rawId,
            }
          }
          return item
        }
      )
      state.createdPlanning[0].products = [...changedProductId]
    },

    changeAmountStore: (state, action) => {
      const {id, storeId, value, name} = action.payload
      const changedProductId = state.createdPlanning[0].products.map((item) => {
        const selectedStoreId = item.records.findIndex(
          (el) => el.storeId === storeId
        )
        if (item.id === id || item.productId === id) {
          if (selectedStoreId === -1) {
            item.records.push({
              storeId,
              storeName: name,
              necessaryAmount: restrictInputValueAfterDot(value.toString()),
            })
          } else {
            item.records[selectedStoreId].necessaryAmount =
              restrictInputValueAfterDot(value.toString())
          }
        }
        return {
          ...item,
          status: 'allowButtonSend',
        }
      })

      state.createdPlanning[0].products = [...changedProductId]
    },
    getRecordById: (state, action) => {
      state.selectedRow = state.createdPlanning[0]?.products.filter(
        (item) => item.productId === action.payload.id
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlannings.pending, (state) => {
      state.isLoading = 'getPlannings'
    })
    builder.addCase(getPlannings.fulfilled, (state, action) => {
      state.plannings = action.payload.plannings
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getPlanningById.pending, (state) => {
      state.isLoading = 'getPlanningById'
    })
    builder.addCase(getPlanningById.fulfilled, (state, action) => {
      state.selectedPlanning = action.payload
      state.createdPlanning[0].products = action.payload?.products?.map(
        (item) => ({
          ...item,
          records: item.records,
        })
      )
      state.totalCount = action?.payload?.totalCount
      state.isLoading = null
    })
    builder.addCase(setPlanning.pending, (state) => {
      state.isLoading = 'setPlanning'
    })
    builder.addCase(setPlanning.fulfilled, (state, action) => {
      state.lastUpdated = Date.now()
      state.isLoading = null
    })
    builder.addCase(deletePlanning.pending, (state) => {
      state.isLoading = 'deletePlanning'
    })
    builder.addCase(deletePlanning.fulfilled, (state, action) => {
      state.lastUpdated = Date.now()
      state.isLoading = null
    })
    builder.addCase(updatePlanning.pending, (state) => {
      state.isLoading = 'updatePlanning'
    })
    builder.addCase(updatePlanning.fulfilled, (state, action) => {
      state.lastUpdated = Date.now()
      state.isLoading = null
    })
    builder.addCase(getNormById.fulfilled, (state, action) => {
      const isShowNecessaryAmount = (item) => {
        if (item.storeId !== null) {
          if (item.balanceAmount <= 0) {
            return item.necessaryAmount
          }
          if (item.balanceAmount > item.necessaryAmount) {
            return 0
          }
          return item.necessaryAmount - item.balanceAmount
        }
        return item.necessaryAmount
      }
      state.createdPlanning[0].products = action.payload?.products?.map(
        (item) => ({
          ...item,
          records: item.records.map((item) => ({
            ...item,
            balanceAmount: item.balanceAmount <= 0 ? 0 : item.balanceAmount,
            necessaryAmount: isShowNecessaryAmount(item),
          })),
        })
      )
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setTableSettings,
  setLastUpdated,
  setDraftNorms,
  changeAmountStore,
  setDraftRawId,
  getRecordById,
  setSelectedNormForDelete,
  clearPlanningRecords,
  setPlanningAddTableSettings,
  resetPlanningAddTableSettings,
} = planningSlice.actions
export default planningSlice.reducer
