import {createSlice} from '@reduxjs/toolkit'

import {
  deleteRefill,
  getRefill,
  getRefills,
  setRefill,
  updateRefill,
} from '../asyncServices/refillService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  refills: [],
  totalCount: 0,
  selectedRefill: null,
  selectedRefills: [],
  tableSettings: {
    f_updatedDateTo: null,
    f_updatedDateFrom: null,
    f_createdDateTo: null,
    f_createdDateFrom: null,
    f_storeId: null,
    f_status: null,
    pageSize: 100,
    page: 1,
    s_direction: 'DESC',
    s_field: 'createdDate',
  },
  lastUpdated: null,
  isLoading: null,
  selectedId: null,
}

const refillSlice = createSlice({
  name: 'refillSlice',
  initialState,
  reducers: {
    setSelectedRefill: (state, action) => {
      state.selectedRefills = action.payload
    },
    resetSelectedRefill: (state) => {
      state.selectedRefill = null
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload.tableSettings,
      }
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRefills.pending, (state) => {
      state.isLoading = 'getRefills'
    })
    builder.addCase(getRefills.fulfilled, (state, action) => {
      state.refills = action.payload.arrivals
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getRefill.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getRefill.fulfilled, (state, action) => {
      state.selectedRefill = action.payload
      state.isLoading = false
    })
    builder.addCase(getRefill.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(setRefill.pending, (state) => {
      state.isLoading = 'setRefill'
    })
    builder.addCase(setRefill.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(updateRefill.pending, (state) => {
      state.isLoading = 'updateRefill'
    })
    builder.addCase(updateRefill.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(deleteRefill.pending, (state) => {
      state.isLoading = 'deleteRefill'
    })
    builder.addCase(deleteRefill.fulfilled, (state, action) => {
      state.selectedStores = action?.payload?.selectedStores
      state.isLoading = null
      state.lastUpdated = Date.now()
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setSelectedRefill,
  setTableSettings,
  setLastUpdated,
  setSelectedId,
  resetSelectedRefill,
} = refillSlice.actions
export default refillSlice.reducer
