import {createSlice} from '@reduxjs/toolkit'
import {getShifts} from '../asyncServices/shiftService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  list: [],
  totalCount: 0,
  tableSettings: {
    s_field: 'openedDate',
    s_direction: 'DESC',
    page: 1,
    pageSize: 100,
    f_openedDateFrom: null,
    f_openedDateTo: null,
    f_closedDateFrom: null,
    f_closedDateTo: null,
    f_storeIds: null,
    f_openedByIds: null,
    f_cashAmountFrom: null,
    f_cashAmountTo: null,
    f_cardAmountFrom: null,
    f_cardAmountTo: null,
    f_totalAmountFrom: null,
    f_totalAmountTo: null,
    f_differenceFrom: null,
    f_differenceTo: null,
  },
  lastUpdated: null,
  isLoading: null,
}

export const loadingStateOptions = {
  getShifts: 'getShifts',
}

const shiftSlice = createSlice({
  name: 'shiftSlice',
  initialState,
  reducers: {
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
    setLastUpdatedNow: (state) => {
      state.lastUpdated = new Date().toISOString()
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShifts.pending, (state) => {
      state.isLoading = loadingStateOptions.getShifts
    })
    builder.addCase(getShifts.fulfilled, (state, action) => {
      state.list = action.payload.rows
      state.totalCount = action.payload.count
      state.isLoading = null
    })
    builder.addCase(getShifts.rejected, (state) => {
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {setTableSettings, setLastUpdatedNow, clearInventoryHistory} =
  shiftSlice.actions
export default shiftSlice.reducer
