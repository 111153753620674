import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {PageHeaderWithMobileMenu} from '../../../../components'
import {PlusOutlined} from '../../../../components/antd-components/icon'
import {
  createItemHeaderMenu,
  isIncludeSelectedFilter,
} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import withAntdModal from '../../../../HOCs/withAntdModal'
import TaskForm from './TaskForm'
import useTasksService from '../../../../services/tasks/taskService'
import {
  tasksData,
  tasksSelectedCheckbox,
  tasksTableSettings,
} from '../../../../redux/selectors/tasksSelector'
import DeleteButton from '../../../../components/antd-components/main-buttons/delete-button/DeleteButton'
import useCommon from '../../../../services/commonService'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'

function TasksHeader() {
  const {t} = useTranslation()
  const {exportExcel} = useCommon()
  const {deleteTaskData} = useTasksService()
  const selectedTasks = useSelector(tasksSelectedCheckbox)
  const tableSettings = useSelector(tasksTableSettings)

  const tasks = useSelector(tasksData)

  const confirmDelete = () => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => deleteTaskData({ids: selectedTasks}),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }
  const AddTaskWithModal = withAntdModal(TaskForm, {
    okButtonText: t('Add'),
    footer: null,
    title: t('Add task'),
    icon: <PlusOutlined />,
    className: 'button add_button',
  })

  const downloadButtonHandler = () => {
    exportExcel({tableName: 'tasks', tableSettings})
  }

  const actionHeader = [
    <AddTaskWithModal key={1} />,
    <DeleteButton
      onClick={confirmDelete}
      disabled={isIncludeSelectedFilter(tasks, selectedTasks)}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]

  const item = createItemHeaderMenu([...actionHeader])
  return (
    <PageHeaderWithMobileMenu title="Tasks" extra={actionHeader} items={item} />
  )
}

export default TasksHeader
