import {createSlice} from '@reduxjs/toolkit'
import {
  deleteRawMaterials,
  getRawBalance,
  getRawMaterial,
  getRawMaterials,
  setRawMaterial,
  updateRawMaterial,
} from '../asyncServices/rawMaterialsService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  rawMaterials: [],
  rawMaterialsBalance: [],
  totalCount: 0,
  selectedRawMaterials: [],
  selectedRawMaterial: null,
  newRawMaterial: null,
  tableSettings: {
    s_direction: 'ASC',
    s_field: 'name',
    f_name: null,
    pageSize: 100,
    page: 1,
  },
  lastUpdated: null,
  isLoading: null,
}

const rawMaterialsSlice = createSlice({
  name: 'rawMaterialsSlice',
  initialState,
  reducers: {
    setSelectedRawMaterials: (state, action) => {
      state.selectedRawMaterials = action.payload
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    clearRawMaterial: (state, action) => {
      state.newRawMaterial = action.payload
    },
    clearAllRawMaterials: (state) => {
      state.rawMaterials = []
    },
    clearSelectedRawMaterial: (state, action) => {
      state.selectedRawMaterial = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
    rawMaterialsReducerReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getRawMaterials.pending, (state) => {
      state.isLoading = 'getRawMaterials'
    })
    builder.addCase(getRawMaterials.fulfilled, (state, action) => {
      state.rawMaterials = action.payload.raws
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getRawMaterial.pending, (state) => {
      state.isLoading = 'getRawMaterial'
    })
    builder.addCase(getRawMaterial.fulfilled, (state, action) => {
      state.selectedRawMaterial = action.payload
      state.isLoading = null
    })
    builder.addCase(setRawMaterial.pending, (state) => {
      state.isLoading = 'setRawMaterials'
    })
    builder.addCase(setRawMaterial.fulfilled, (state, action) => {
      state.isLoading = null
      state.newRawMaterial = action.payload
      state.selectedRawMaterial = action.payload
    })
    builder.addCase(updateRawMaterial.pending, (state) => {
      state.isLoading = 'updateRawMaterial'
    })
    builder.addCase(updateRawMaterial.fulfilled, (state) => {
      state.isLoading = null
    })
    builder.addCase(deleteRawMaterials.pending, (state) => {
      state.isLoading = 'deleteRawMaterials'
    })
    builder.addCase(deleteRawMaterials.fulfilled, (state, action) => {
      state.isLoading = null
      state.selectedRawMaterials = action.payload.selectedRawMaterials
    })
    builder.addCase(getRawBalance.pending, (state) => {
      state.rawMaterialsBalance = []
    })
    builder.addCase(getRawBalance.fulfilled, (state, action) => {
      state.rawMaterialsBalance = action.payload.rawBalances
    })
    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setSelectedRawMaterials,
  setTableSettings,
  setLastUpdated,
  rawMaterialsReducerReset,
  clearRawMaterial,
  clearSelectedRawMaterial,
  clearAllRawMaterials,
} = rawMaterialsSlice.actions
export default rawMaterialsSlice.reducer
