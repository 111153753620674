import {createSlice} from '@reduxjs/toolkit'

import {
  deleteMovementItem,
  getMovement,
  getMovements,
  setMovement,
  updateMovement,
} from '../asyncServices/movementsService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  movements: [],
  totalCount: 0,
  selectedMovement: null,
  selectedMovements: [],
  tableSettings: {
    f_updatedBy: null,
    f_createdBy: null,
    f_updatedDateTo: null,
    f_updatedDateFrom: null,
    f_createdDateTo: null,
    f_createdDateFrom: null,
    f_status: null,
    f_comment: null,
    f_toStoreId: null,
    f_fromStoreId: null,
    s_field: 'createdDate',
    s_direction: 'DESC',
    page: 1,
    pageSize: 100,
  },
  lastUpdated: null,
  isLoading: null,
  selectedId: null,
}

const movementSlice = createSlice({
  name: 'movementSlice',
  initialState,
  reducers: {
    resetSelectedMovement: (state) => {
      state.selectedMovement = null
    },
    setSelectedMovement: (state, action) => {
      state.selectedMovement = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload.tableSettings,
      }
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMovements.pending, (state) => {
      state.isLoading = 'getMovements'
    })
    builder.addCase(getMovements.fulfilled, (state, action) => {
      state.movements = action.payload.storeBalanceMoves
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getMovement.pending, (state) => {
      state.isLoading = 'getMovement'
    })
    builder.addCase(getMovement.fulfilled, (state, action) => {
      state.selectedMovement = action.payload
      state.isLoading = null
    })
    builder.addCase(setMovement.pending, (state) => {
      state.isLoading = 'setMovement'
    })
    builder.addCase(setMovement.fulfilled, (state) => {
      state.isLoading = null
    })
    builder.addCase(updateMovement.pending, (state) => {
      state.isLoading = 'updateMovement'
    })
    builder.addCase(updateMovement.fulfilled, (state) => {
      state.isLoading = null
    })
    builder.addCase(deleteMovementItem.pending, (state) => {
      state.isLoading = 'getMovements'
    })
    builder.addCase(deleteMovementItem.fulfilled, (state) => {
      state.isLoading = null
      state.lastUpdated = Date.now()
    })
    builder.addCase(deleteMovementItem.rejected, (state) => {
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setSelectedMovement,
  setTableSettings,
  setLastUpdated,
  resetSelectedMovement,
} = movementSlice.actions
export default movementSlice.reducer
