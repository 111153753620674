import {createSlice} from '@reduxjs/toolkit'
import {
  deleteUnits,
  getUnit,
  getUnits,
  setUnit,
  updateUnit,
} from '../asyncServices/unitsService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  units: [],
  totalCount: 0,
  selectedUnits: [],
  selectedUnit: null,
  tableSettings: {
    s_direction: 'ASC',
    s_field: 'name',
    f_name: null,
    pageSize: 100,
    page: 1,
  },
  lastUpdated: null,
  isLoading: null,
}

const unitsSlice = createSlice({
  name: 'unitsSlice',
  initialState,
  reducers: {
    setSelectedUnits: (state, action) => {
      state.selectedUnits = action.payload
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
    unitsReducerReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getUnits.pending, (state) => {
      state.isLoading = 'getUnits'
    })
    builder.addCase(getUnits.fulfilled, (state, action) => {
      state.units = action.payload.units
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getUnit.pending, (state) => {
      state.isLoading = 'getUnit'
    })
    builder.addCase(getUnit.fulfilled, (state, action) => {
      state.selectedUnit = action.payload
      state.isLoading = null
    })
    builder.addCase(setUnit.pending, (state) => {
      state.isLoading = 'setUnits'
    })
    builder.addCase(setUnit.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(updateUnit.pending, (state) => {
      state.isLoading = 'updateUnit'
    })
    builder.addCase(updateUnit.fulfilled, (state, action) => {
      state.isLoading = null
    })
    builder.addCase(deleteUnits.pending, (state) => {
      state.isLoading = 'deleteUnits'
    })
    builder.addCase(deleteUnits.fulfilled, (state, action) => {
      state.selectedUnits = action.payload.selectedUnits
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setSelectedUnits,
  setTableSettings,
  setLastUpdated,
  unitsReducerReset,
} = unitsSlice.actions
export default unitsSlice.reducer
