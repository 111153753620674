import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from '../../../../components/antd-components/icon'
import variables from '../../../../globalStyle/colors.scss'

import useDebounce from '../../../../hooks/useDebounce'

import StoreForm from './StoreForm'
import withAntdModal from '../../../../HOCs/withAntdModal'
import {isOpenModalWindow} from '../../../../redux/selectors/storeSelector'
import {
  createItemHeaderMenu,
  isIncludeSelectedFilter,
} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'
import {PageHeaderWithMobileMenu} from '../../../../components'
import AddButton from '../../../../components/antd-components/main-buttons/add-button/AddButton'
import DeleteButton from '../../../../components/antd-components/main-buttons/delete-button/DeleteButton'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'

function StoreHeader({
  setStoreTableSettings,
  deleteStoresData,
  changeIsOpenModalWindow,
  form,
}) {
  const {t} = useTranslation()
  const selectedStores = useSelector((state) => state.stores.selectedStores)
  const stores = useSelector((state) => state.stores.stores)
  const search = useSelector((state) => state.stores.tableSettings.f_name)
  const tableSettings = useSelector((state) => state.stores.tableSettings)
  const isModalWindowStatus = useSelector(isOpenModalWindow)
  const [searchValue, setSearchValue] = useState('')
  const {exportExcel} = useCommon()

  const AddStoreBalanceWithModal = withAntdModal(StoreForm, {
    okButtonText: t('Add'),
    footer: null,
    title: t('Add record'),
    icon: <PlusOutlined />,
    style: {
      textTransform: 'uppercase',
      width: '100%',
      color: variables.add,
    },
    text: t('To add a store, please enter a name and click add.'),
  })

  const handleSubmitDelete = () => deleteStoresData({ids: selectedStores})

  const confirmDelete = () => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: handleSubmitDelete,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }
  const debouncedValue = useDebounce(searchValue, 500)

  const onClickOpenModalWindow = () => {
    form.resetFields()
    changeIsOpenModalWindow(true)
  }
  const storeActionHeader = [
    <SearchAntdComponent
      key={1}
      value={searchValue}
      onChangeText={setSearchValue}
      suffix={<SearchOutlined />}
    />,
    <AddButton onClick={onClickOpenModalWindow} />,
    <DeleteButton
      onClick={confirmDelete}
      disabled={isIncludeSelectedFilter(stores, selectedStores)}
    />,
    <DownloadButton
      onClick={() => {
        exportExcel({tableSettings, tableName: 'dictionary/stores'})
      }}
    />,
  ]

  useEffect(() => {
    const debouncedSearchValue = !debouncedValue ? null : debouncedValue
    if (search !== debouncedSearchValue) {
      setStoreTableSettings({f_name: debouncedSearchValue})
    }
  }, [debouncedValue])
  const item = createItemHeaderMenu(storeActionHeader)
  return (
    <PageHeaderWithMobileMenu
      title="Stores"
      items={item}
      extra={storeActionHeader}
    />
  )
}

export default StoreHeader
