import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import useCustomNavigate from '../../../../hooks/useCustomNavigate'
import {ExclamationCircleOutlined} from '../../../../components/antd-components/icon'

import {
  createItemHeaderMenu,
  isIncludeSelectedFilter,
} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import {PageHeaderWithMobileMenu} from '../../../../components'
import AddButton from '../../../../components/antd-components/main-buttons/add-button/AddButton'
import DeleteButton from '../../../../components/antd-components/main-buttons/delete-button/DeleteButton'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'

function SuppliesHeader({deleteSuppliesData, suppliesTableSettings}) {
  const {t} = useTranslation()
  const navigate = useCustomNavigate()
  const {exportExcel} = useCommon()
  const onAddRaw = () => navigate('/supplies/add', {})
  const handleSubmitDelete = () => deleteSuppliesData({ids: selectedSupplies})

  const confirmDelete = () => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleSubmitDelete(),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const selectedSupplies = useSelector(
    (state) => state.supplies.selectedSupplies
  )
  const supplies = useSelector((state) => state.supplies.supplies)

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `supplies`,
      tableSettings: suppliesTableSettings,
    })
  }

  const ActionButton = [
    <AddButton onClick={onAddRaw} />,
    <DeleteButton
      disabled={isIncludeSelectedFilter(supplies, selectedSupplies)}
      onClick={confirmDelete}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  const item = createItemHeaderMenu(ActionButton)

  return (
    <PageHeaderWithMobileMenu
      title={t('Supplies')}
      items={item}
      extra={ActionButton}
    />
  )
}

export default SuppliesHeader
