import {useDispatch} from 'react-redux'

import {
  deleteRawMaterials,
  getRawBalance,
  getRawMaterial,
  getRawMaterials,
  setRawMaterial,
  updateRawMaterial,
} from '../../redux/asyncServices/rawMaterialsService'
import {
  clearAllRawMaterials,
  clearRawMaterial,
  clearSelectedRawMaterial,
  rawMaterialsReducerReset,
  setLastUpdated,
  setSelectedRawMaterials,
  setTableSettings,
} from '../../redux/slices/rawMaterialsSlice'

function useRawMaterials() {
  const dispatch = useDispatch()

  const rawMaterialsLastUpdated = () => dispatch(setLastUpdated(Date.now()))

  const getRawMaterialsData = (params) => dispatch(getRawMaterials({params}))

  const getRawMaterialsBalanceData = (params) =>
    dispatch(getRawBalance({params}))

  const getRawMaterialById = ({id}) => dispatch(getRawMaterial({id}))

  const selectRawMaterials = (data) => dispatch(setSelectedRawMaterials(data))

  const addRawMaterial = async (data, onClose) => {
    await dispatch(setRawMaterial(data)).then((response) => {
      if (response.payload?.id) {
        rawMaterialsLastUpdated()
        if (onClose) {
          onClose()
        }
      }
    })
  }

  const updateRawMaterialData = async (data, onClose) => {
    await dispatch(updateRawMaterial(data)).then((response) => {
      if (response.payload?.id) {
        rawMaterialsLastUpdated()
        if (onClose) {
          onClose()
        }
      }
    })
  }

  const deleteRawMaterialsData = async ({ids}) => {
    await dispatch(deleteRawMaterials({ids}))
    rawMaterialsLastUpdated()
  }

  const setRawMaterialTableSettings = async (tableSettings = {}) => {
    await dispatch(setTableSettings(tableSettings))
    rawMaterialsLastUpdated()
  }
  const rawMaterialsReset = () => dispatch(rawMaterialsReducerReset())
  const resetSelectedRawMaterial = () => dispatch(clearSelectedRawMaterial())

  const addRawMaterialFromComponent = async (data, onClose) => {
    await dispatch(setRawMaterial(data)).then((response) => {
      if (response.payload?.id) {
        getRawMaterialsData()
        rawMaterialsLastUpdated()
        onClose()
      }
    })
  }

  const clearNewRawMaterial = async (data) => {
    await dispatch(clearRawMaterial(data))
  }

  const clearRawMaterialsList = () => {
    dispatch(clearAllRawMaterials())
  }

  return {
    getRawMaterialsData,
    getRawMaterialById,
    addRawMaterial,
    updateRawMaterialData,
    selectRawMaterials,
    deleteRawMaterialsData,
    setRawMaterialTableSettings,
    rawMaterialsReset,
    addRawMaterialFromComponent,
    clearNewRawMaterial,
    resetSelectedRawMaterial,
    clearRawMaterialsList,
    getRawMaterialsBalanceData,
  }
}

export default useRawMaterials
