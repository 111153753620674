import {createSlice} from '@reduxjs/toolkit'
import moment from 'moment'
import {getPurchase, getPurchases} from '../asyncServices/purchaseService'
import {filterAndRemoveFields} from '../../utils/helpers'
import {resetReduxStore} from '../resetStore'

const initialState = {
  purchases: [],
  selectedPurchase: null,
  summary: [],
  totalCount: 0,
  tableSettings: {
    f_user: null,
    f_createdDateTo: null,
    f_store: null,
    f_createdDateFrom: moment().startOf('day').toISOString(),
    s_field: 'createdDate',
    s_direction: 'DESC',
    withSummary: true,
    page: 1,
    pageSize: 100,
  },
  lastUpdated: null,
  isLoading: null,
  isTableSettingsSync: false,
}

const equal = require('deep-equal')

const purchaseSlice = createSlice({
  name: 'purchaseSlice',
  initialState,
  reducers: {
    setTableSettingFromUrl: (state, action) => {
      const tableSettingsFromUrl = JSON.parse(action.payload)
      const currentTableSettings = filterAndRemoveFields(state.tableSettings)
      const isTableSettingsEqual = equal(
        tableSettingsFromUrl,
        currentTableSettings
      )
      if (!isTableSettingsEqual) {
        const newTableSettings = {
          ...initialState.tableSettings,
          ...tableSettingsFromUrl,
          f_createdDateFrom: tableSettingsFromUrl.f_createdDateFrom
            ? tableSettingsFromUrl.f_createdDateFrom
            : null,
        }
        state.tableSettings = newTableSettings
        state.lastUpdated = Date.now()
      }
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    clearSelectedPurchase: (state) => {
      state.selectedPurchase = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPurchases.pending, (state) => {
      state.isLoading = 'getPurchases'
    })
    builder.addCase(getPurchases.fulfilled, (state, action) => {
      state.purchases = action.payload.purchaseChecks
      state.summary = action.payload.summary
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getPurchases.rejected, (state) => {
      state.isLoading = null
    })
    builder.addCase(getPurchase.pending, (state) => {
      state.isLoading = 'getPurchase'
    })
    builder.addCase(getPurchase.fulfilled, (state, action) => {
      state.selectedPurchase = action.payload
      state.isLoading = null
    })
    builder.addCase(getPurchase.rejected, (state) => {
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setTableSettings,
  setLastUpdated,
  clearSelectedPurchase,
  setTableSettingFromUrl,
} = purchaseSlice.actions
export default purchaseSlice.reducer
