import {createSlice} from '@reduxjs/toolkit'
import {
  getInventory,
  getInventoryById,
  getInventoryHistory,
} from '../asyncServices/inventoryService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  inventory: [],
  selectedInventory: null,
  totalCount: 0,
  inventoryHistory: [],
  tableSettings: {
    s_field: 'submissionDate',
    s_direction: 'DESC',
    page: 1,
    pageSize: 100,
    f_submissionDateTo: null,
    f_submissionDateFrom: null,
    f_confirmationDateTo: null,
    f_confirmationDateFrom: null,
    f_submittedById: null,
    f_status: null,
    f_storeId: null,
    f_succeed: null,
  },
  lastUpdated: null,
  isLoading: null,
}

const inventorySlice = createSlice({
  name: 'inventorySlice',
  initialState,
  reducers: {
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    clearInventoryHistory: (state) => {
      state.inventoryHistory = []
    },
    clearSelectedInventory: (state) => {
      state.selectedInventory = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInventory.pending, (state) => {
      state.isLoading = 'getInventory'
    })
    builder.addCase(getInventory.fulfilled, (state, action) => {
      state.inventory = action.payload.inventories
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getInventoryById.pending, (state) => {
      state.isLoading = 'getInventoryById'
    })
    builder.addCase(getInventoryById.fulfilled, (state, action) => {
      state.selectedInventory = action.payload
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getInventoryHistory.pending, (state) => {
      state.isLoading = 'getInventoryHistory'
    })
    builder.addCase(getInventoryHistory.fulfilled, (state, action) => {
      state.inventoryHistory = action.payload.historyStoreBalance

      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setTableSettings,
  setLastUpdated,
  clearInventoryHistory,
  clearSelectedInventory,
} = inventorySlice.actions
export default inventorySlice.reducer
