import {createSlice} from '@reduxjs/toolkit'
import {getOrderById, getOrders} from '../asyncServices/ordersService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  list: [],
  paginationData: {
    totalCount: 0,
  },
  isLoading: null,
  selected: null,
  tableSettings: {
    s_field: 'deliveryDate',
    s_direction: 'DESC',
    page: 1,
    pageSize: 100,
    f_createdDateTo: null,
    f_createdDateFrom: null,
    f_deliveryDateTo: null,
    f_deliveryDateFrom: null,
    f_description: null,
    f_createdById: null,
    f_deliveryFromId: null,
    f_delivered: null,
  },
}

export const isLoadingOrdersStateOptions = {
  GET_ORDERS: 'getOrders',
  GET_BY_ID: 'getById',
}

const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state) => {
      state.isLoading = isLoadingOrdersStateOptions.GET_ORDERS
    })
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.list = action.payload.orders
      state.paginationData.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getOrders.rejected, (state) => {
      state.isLoading = null
    })

    builder.addCase(getOrderById.pending, (state) => {
      state.isLoading = isLoadingOrdersStateOptions.GET_BY_ID
    })
    builder.addCase(getOrderById.fulfilled, (state, action) => {
      state.selected = action.payload
      state.isLoading = null
    })
    builder.addCase(getOrderById.rejected, (state) => {
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {setTableSettings} = orderSlice.actions
export default orderSlice.reducer
