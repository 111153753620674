import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  deleteSupplies,
  getSupplies,
  getSupply,
  setSupply,
  updateSupply,
} from '../../redux/asyncServices/suppliesService'
import useCustomNavigate from '../../hooks/useCustomNavigate'

import {
  setLastUpdated,
  setSelectedId,
  setSelectedSupplies,
  setTableSettings,
} from '../../redux/slices/suppliesSlice'
import notify from '../../components/antd-components/notification/notify'

function useSupplies() {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const navigate = useCustomNavigate()

  const successAddSupplies = (description) => {
    notify({
      description: t(description),
      type: 'success',
    })
    navigate('/supplies')
  }

  const suppliesLastUpdated = () => dispatch(setLastUpdated(Date.now()))
  const getSuppliesData = (params) => dispatch(getSupplies({params}))
  const getSupplyById = ({id}) => dispatch(getSupply({id}))
  const addSupply = async (data) => {
    dispatch(setSupply(data)).then((responce) => {
      if (responce.payload) {
        successAddSupplies(t('Supplies successfully added'))
      }
    })
  }
  const updateSupplyData = async (data) => {
    dispatch(updateSupply(data)).then((responce) => {
      if (responce.payload) {
        successAddSupplies(t('Supplies successfully update'))
      }
    })
  }

  const selectSupplies = (data) => dispatch(setSelectedSupplies(data))

  const deleteSuppliesData = async ({ids}) => {
    dispatch(deleteSupplies({ids}))
  }
  const setSuppliesTableSettings = (tableSettings = {}) => {
    dispatch(setTableSettings({tableSettings}))
    suppliesLastUpdated()
  }

  const setSelectedSuppliesId = (id) => {
    dispatch(setSelectedId(id))
  }

  return {
    getSuppliesData,
    addSupply,
    selectSupplies,
    setSuppliesTableSettings,
    deleteSuppliesData,
    updateSupplyData,
    getSupplyById,
    setSelectedSuppliesId,
  }
}

export default useSupplies
