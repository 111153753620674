import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from '../../../../components/antd-components/icon'
import variables from '../../../../globalStyle/colors.scss'
import WriteOffForm from './WriteOffForm'
import useDebounce from '../../../../hooks/useDebounce'
import withAntdModal from '../../../../HOCs/withAntdModal'

import {
  createItemHeaderMenu,
  isIncludeSelectedFilter,
} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'
import {PageHeaderWithMobileMenu} from '../../../../components'
import DeleteButton from '../../../../components/antd-components/main-buttons/delete-button/DeleteButton'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useCommon from '../../../../services/commonService'
import {writeOffReasonTableSettingsSelector} from '../../../../redux/selectors/writeOffReasonSelector'

function WriteOffHeader({
  setWriteOffReasonTableSettings,
  deleteWriteOffReasonData,
}) {
  const {t} = useTranslation()
  const {exportExcel} = useCommon()

  const tableSettings = useSelector(writeOffReasonTableSettingsSelector)
  const selectedWriteOff = useSelector(
    (state) => state.writeOff.selectedWriteOffReasons
  )
  const writeOff = useSelector((state) => state.writeOff.writeOffReason)
  const search = useSelector((state) => state.writeOff.tableSettings.f_name)

  const [searchValue, setSearchValue] = useState('')

  const AddWriteOffFormWithModal = withAntdModal(WriteOffForm, {
    okButtonText: t('Add'),
    footer: null,
    title: t('Add record'),
    icon: <PlusOutlined />,
    style: {
      width: '100%',
      textTransform: 'uppercase',
      color: variables.add,
    },
    text: t('To add a write-off, please enter a name and click add.'),
  })

  const handleSubmitDelete = () =>
    deleteWriteOffReasonData({ids: selectedWriteOff})

  const confirmDelete = () => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: handleSubmitDelete,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const debouncedValue = useDebounce(searchValue, 500)
  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `dictionary/woreasons`,
      tableSettings,
    })
  }

  const actionWriteOffHeader = [
    <SearchAntdComponent
      key={1}
      value={searchValue}
      onChangeText={setSearchValue}
      suffix={<SearchOutlined />}
    />,
    <AddWriteOffFormWithModal key={3} text={t('Add')} />,
    <DeleteButton
      onClick={confirmDelete}
      disabled={isIncludeSelectedFilter(writeOff, selectedWriteOff)}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  const item = createItemHeaderMenu(actionWriteOffHeader)

  useEffect(() => {
    const debouncedSearchValue = !debouncedValue ? null : debouncedValue
    if (search !== debouncedSearchValue) {
      setWriteOffReasonTableSettings({
        f_name: debouncedSearchValue,
      })
    }
  }, [debouncedValue])

  return (
    <PageHeaderWithMobileMenu
      title="Write-off reason"
      extra={actionWriteOffHeader}
      items={item}
    />
  )
}

export default WriteOffHeader
