import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Empty, Form} from 'antd'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {
  InputNumberForm,
  TextAreaForm,
} from '../../../../../components/antd-components/form'
import Counter from '../../../../../components/Counter'
import {currencyMultiply} from '../../../../../utils/helpers'
import showConfirm from '../../../../../components/PopupConfirm'

import './style.scss'
import DeleteActionButton from '../../../../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'
import useValidationSchema from './productFieldsValidation'

function OrderProductsForm({form}) {
  const {t} = useTranslation()
  const schema = useValidationSchema()

  const validateField = useCallback(
    async (rule, value) => {
      try {
        const fieldPath = rule.field
        await schema.validateSyncAt(fieldPath, form.getFieldsValue(true))
      } catch (error) {
        throw new Error(error.message)
      }
    },
    [form, schema]
  )

  const handleDeleteProduct = (id) => {
    const newOrderProductsList = form
      .getFieldValue('orderProducts')
      .filter((item) => item.id !== id)
    form.setFieldsValue({
      orderProducts: newOrderProductsList,
    })
  }

  const handleShowDeleteConfirmation = (id, productName) => {
    showConfirm({
      title: `${t('Confirm delete')} ${productName}`,
      okFunction: () => handleDeleteProduct(id),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  return (
    <div className="order-form_product-table-container">
      <table className="order-form_product-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{t('Name')}</th>
            <th>{t('Amount')}</th>
            <th>{t('Price')}</th>
            <th>{t('Total')}</th>
            <th>{t('Actions')}</th>
          </tr>
        </thead>
      </table>
      <div className="table-body">
        <table className="order-form_product-table">
          <tbody>
            <Form.List name="orderProducts">
              {(orderProducts) =>
                orderProducts.length > 0 ? (
                  orderProducts.map(({key}, index) => {
                    const orderProducts = form.getFieldValue('orderProducts')
                    const orderProductData = orderProducts[index]

                    const handleChangeAmount = (newAmount) => {
                      const newTotal = currencyMultiply(
                        newAmount,
                        orderProductData.price
                      )
                      orderProductData.amount = newAmount
                      orderProductData.total = newTotal

                      form.setFieldsValue({orderProducts})
                    }

                    const handleChangePrice = (newPrice) => {
                      const newTotal = currencyMultiply(
                        newPrice,
                        orderProductData.amount
                      )
                      orderProductData.price = newPrice
                      orderProductData.total = newTotal

                      form.setFieldsValue({orderProducts})
                    }

                    return (
                      <React.Fragment key={key}>
                        <tr>
                          <td rowSpan="2">
                            <div className="cell-wrapper">{index + 1}</div>
                          </td>
                          <td>
                            <div className="cell-wrapper left">
                              {orderProductData.product.name}
                            </div>
                          </td>
                          <td>
                            {orderProductData.product.piece ? (
                              <Counter
                                value={orderProductData.amount}
                                id={orderProductData.id}
                                increment={() => {
                                  handleChangeAmount(
                                    orderProductData.amount + 1
                                  )
                                }}
                                decrement={() => {
                                  if (orderProductData.amount === 1) {
                                    handleDeleteProduct(orderProductData.id)
                                  } else {
                                    handleChangeAmount(
                                      orderProductData.amount - 1
                                    )
                                  }
                                }}
                              />
                            ) : (
                              <InputNumberForm
                                name={[index, 'amount']}
                                rules={{validator: validateField}}
                                controls={false}
                                value={orderProductData.amount}
                                onChange={handleChangeAmount}
                                formWrapperClassName="clear-form-margin"
                                unitType="weight"
                              />
                            )}
                          </td>
                          <td>
                            <div className="cell-wrapper">
                              <InputNumberForm
                                name={[index, 'price']}
                                rules={{validator: validateField}}
                                value={orderProductData.price}
                                onChange={handleChangePrice}
                                formWrapperClassName="clear-form-margin"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="cell-wrapper">
                              {orderProductData.total}
                            </div>
                          </td>
                          <td rowSpan="2">
                            <div className="cell-wrapper">
                              <DeleteActionButton
                                onClick={() =>
                                  handleShowDeleteConfirmation(
                                    orderProductData.id,
                                    orderProductData.product.name
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <TextAreaForm
                              name={[index, 'comment']}
                              rules={{validator: validateField}}
                              rows={2}
                              classname="clear-form-margin"
                              placeholder={t('Comment')}
                              value={orderProductData.comment}
                              onChange={(e) => {
                                orderProductData.comment = e.target.value
                                form.setFieldsValue({orderProducts})
                              }}
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <tr className="placeholder">
                    <td colSpan={6}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                )
              }
            </Form.List>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OrderProductsForm
