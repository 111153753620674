import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Col, Form, Row} from 'antd'
import useAccounting from '../../../../services/accounting/accountingService'
import AccountingValidation from '../validation/AccountingValidation'
import {
  AutoCompleteComponent,
  InputNumberForm,
  SwitchForm,
  TextAreaForm,
} from '../../../../components/antd-components/form'
import {isCheckComa} from '../../../../utils/helpers'
import {
  ACCOUNTING_TYPES,
  accountingType,
  formProps,
} from '../../../../constants'
import {
  companyAccountingCategoriesSelector,
  getAccountingCategoryLoading,
  storeAccountingCategoriesSelector,
} from '../../../../redux/selectors/accountingCategorySelector'

import '../style.scss'
import './style.scss'
import CancelButton from '../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

function AccountingForm({onClose, formData, form, isStoreAccounting}) {
  const {t} = useTranslation()
  const accountingProps = useAccounting()
  const {addAccounting, updateAccountingData} = accountingProps
  const isLoadingAccountingCategory = useSelector(getAccountingCategoryLoading)
  const accountingCategories = useSelector(
    isStoreAccounting
      ? storeAccountingCategoriesSelector
      : companyAccountingCategoriesSelector
  )
  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedAccountingType, setSelectedAccountingType] =
    useState('expenses')

  const filteredAccountingCategories = useMemo(() => {
    const isExpense = selectedAccountingType === ACCOUNTING_TYPES.EXPENSES
    return accountingCategories?.filter(
      (category) => category.credit === isExpense
    )
  }, [selectedAccountingType])

  const accountingRules = AccountingValidation({
    type: selectedAccountingType,
  })

  const {modalProps} = formProps
  const isLoading = useSelector(
    (state) => state.accounting.isLoading === 'setAccounting'
  )

  useEffect(() => {
    if (formData?.id) {
      form.setFieldsValue(formData)
      const accountingCategoryId = formData.accountingCategory.id
      const selectedCategory = accountingCategories.find(
        (category) => category.id === accountingCategoryId
      )
      setSelectedCategory(selectedCategory)
      if (selectedCategory) {
        const accountingType = selectedCategory.credit ? 'expenses' : 'income'
        setSelectedAccountingType(accountingType)
        form.setFieldValue('type', accountingType)
      }
    }
  }, [formData?.id])

  // clean form on unmount
  useEffect(
    () => () => {
      form.resetFields()
    },
    []
  )

  const onSubmitAddAccounting = (values) => {
    const {amount, isCash} = values
    addAccounting({
      ...values,
      amount: isCheckComa(amount),
      isCash,
    })
    onClose()
  }

  const onSubmitEditAccounting = (values) => {
    const {amount, isCash, accountingCategoryId, comment} = values
    updateAccountingData({
      ...values,
      id: formData.id,
      amount: isCheckComa(amount),
      isCash,
      comment,
      accountingCategoryId,
    })
    onClose()
  }

  const onCategoryChange = (selectedCategoryId) => {
    if (selectedCategoryId) {
      const selectedCategory = accountingCategories.find(
        (category) => category.id === selectedCategoryId
      )
      setSelectedCategory(selectedCategory)
    } else {
      setSelectedCategory(undefined)
    }
  }

  const onAccountingTypeChange = (value) => {
    setSelectedAccountingType(value)
    form.resetFields(['accountingCategoryId'])
    const amountFieldValue = form.getFieldValue('amount')
    const isValueNumeric = /^-?\d+(\.\d+)?$/.test(amountFieldValue)
    if (value === ACCOUNTING_TYPES.EXPENSES && isValueNumeric) {
      const negativeAmountValue = Math.abs(amountFieldValue) * -1
      form.setFieldsValue({amount: negativeAmountValue})
    }
    if (value === ACCOUNTING_TYPES.INCOME && isValueNumeric) {
      const positiveAmountValue = Math.abs(amountFieldValue)
      form.setFieldsValue({amount: positiveAmountValue})
    }
  }

  const onAmountFieldChange = (value) => {
    if (value && selectedAccountingType === ACCOUNTING_TYPES.EXPENSES) {
      const negativeAmountValue = Math.abs(value) * -1
      form.setFieldsValue({
        amount: value.endsWith('.')
          ? `${negativeAmountValue}.`
          : negativeAmountValue,
      })
    }
  }

  const layout = {
    labelCol: {span: 5},
    wrapperCol: {span: 20},
  }

  return (
    <Form
      form={form}
      onFinish={formData?.id ? onSubmitEditAccounting : onSubmitAddAccounting}
      {...layout}
    >
      <Row gutter={24}>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <AutoCompleteComponent
            options={accountingType?.map((item) => ({
              id: item?.id,
              name: t(item?.name),
              value: item?.id,
            }))}
            allowClear={false}
            name="type"
            defaultValue={selectedAccountingType}
            placeholder={t('Type')}
            label={t('Type')}
            onChange={(e) => onAccountingTypeChange(e)}
            className="content"
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <AutoCompleteComponent
            options={filteredAccountingCategories.map((item) => ({
              id: item?.id,
              name: t(item?.title),
              value: item?.id,
            }))}
            disabled={!accountingCategories?.length}
            name="accountingCategoryId"
            loading={isLoadingAccountingCategory}
            placeholder={t('Category')}
            label={t('Category')}
            rules={{...accountingRules, required: true}}
            className="content"
            onChange={onCategoryChange}
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputNumberForm
            placeholder={t(
              selectedCategory?.credit
                ? 'Only negative number'
                : 'Only positive number'
            )}
            name="amount"
            label={t('Sum')}
            controls={false}
            onWheel={(e) => e.target.blur()}
            rules={{...accountingRules, required: true}}
            className="content"
            onChange={onAmountFieldChange}
          />
        </Col>
        <Col className="gutter-row" span={24}>
          <SwitchForm
            label={t('Method')}
            checkedChildren={t('Cash')}
            unCheckedChildren={t('Card')}
            name="isCash"
            initialValue
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <TextAreaForm
            rows={3}
            placeholder={t('Comment')}
            name="comment"
            label={t('Comment')}
            rules={accountingRules}
          />
        </Col>
        <Col className="button_wrapper_action button">
          <Col className="button">
            <CancelButton onClick={onClose} />
          </Col>

          <Col className="button">
            <SaveButton loading={isLoading} htmlType="submit" />
          </Col>
        </Col>
      </Row>
    </Form>
  )
}

export default AccountingForm
