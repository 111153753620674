import {useDispatch, useSelector} from 'react-redux'

import {
  getPurchase,
  getPurchases,
} from '../../redux/asyncServices/purchaseService'
import {
  clearSelectedPurchase,
  setLastUpdated,
  setTableSettingFromUrl,
  setTableSettings,
} from '../../redux/slices/purchaseSlice'

function usePurchaseService() {
  const dispatch = useDispatch()

  const purchaseLastUpdated = () => dispatch(setLastUpdated(Date.now()))

  const getPurchaseData = (params) => dispatch(getPurchases({params}))

  const getPurchaseById = (id) => dispatch(getPurchase({id}))

  const setPurchaseTableSettings = async (tableSettings = {}) => {
    await dispatch(setTableSettings(tableSettings))
    purchaseLastUpdated()
  }

  const selectedPurchase = useSelector(
    (state) => state.purchase.selectedPurchase
  )
  const setTableSettingsFromUrl = (tableSettings) => {
    dispatch(setTableSettingFromUrl(tableSettings))
  }

  const resetSelectedPurchase = () => dispatch(clearSelectedPurchase())

  return {
    getPurchaseData,
    getPurchaseById,
    setPurchaseTableSettings,
    selectedPurchase,
    resetSelectedPurchase,
    setTableSettingsFromUrl,
  }
}

export default usePurchaseService
