import {useDispatch} from 'react-redux'

import {
  categoriesReducerReset,
  setLastUpdated,
  setSearchValueCategories,
  setSelectedCategories,
} from '../../redux/slices/categorySlice'
import {
  deleteCategories,
  getCategories,
  getCategory,
  setCategory,
  updateCategory,
} from '../../redux/asyncServices/categoryService'

function useCategory() {
  const dispatch = useDispatch()
  const categoriesLastUpdated = () => dispatch(setLastUpdated(Date.now()))
  const getCategoriesData = (params) => dispatch(getCategories({params}))
  const getCategoryById = (data) => dispatch(getCategory(data))
  const addCategory = async (data, onClose) => {
    await dispatch(setCategory(data)).then((response) => {
      if (response?.payload?.id) {
        categoriesLastUpdated()
        if (onClose) {
          onClose()
        }
      }
    })
  }
  const updateCategoryData = async (data, onClose) => {
    await dispatch(updateCategory(data)).then((response) => {
      if (response?.payload?.id) {
        categoriesLastUpdated()
        if (onClose) {
          onClose()
        }
      }
    })
  }
  const selectCategories = (data) => dispatch(setSelectedCategories(data))

  const deleteCategoriesData = async ({ids}) => {
    await dispatch(deleteCategories({ids}))
    categoriesLastUpdated()
  }

  const setSearchCategory = async (data) => {
    await dispatch(setSearchValueCategories(data))
  }
  const categoriesReset = () => dispatch(categoriesReducerReset())
  return {
    getCategoriesData,
    getCategoryById,
    addCategory,
    updateCategoryData,
    selectCategories,
    deleteCategoriesData,
    setSearchCategory,
    categoriesReset,
  }
}

export default useCategory
