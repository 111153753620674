import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useEffect, useState} from 'react'
import {
  ExclamationCircleOutlined,
  SearchOutlined,
} from '../../../../components/antd-components/icon'

import {
  createItemHeaderMenu,
  isIncludeSelectedFilter,
} from '../../../../utils/helpers'
import showConfirm from '../../../../components/PopupConfirm'
import {PageHeaderWithMobileMenu} from '../../../../components'
import SearchAntdComponent from '../../../../components/antd-components/search-component/SearchComponent'

import useDebounce from '../../../../hooks/useDebounce'
import useCommon from '../../../../services/commonService'
import {
  accountingCategoryTableSettings,
  companyAccountingCategoriesSelector,
  searchValueAccountingCategory,
  selectedInTable,
  storeAccountingCategoriesSelector,
} from '../../../../redux/selectors/accountingCategorySelector'
import useAccountingCategoryService from '../../../../services/accountingCategory/accountingCategoryService'
import AddButton from '../../../../components/antd-components/main-buttons/add-button/AddButton'
import DeleteButton from '../../../../components/antd-components/main-buttons/delete-button/DeleteButton'
import DownloadButton from '../../../../components/antd-components/main-buttons/download_button/DownloadButton'

function AccountingCategoryHeader({form}) {
  const {t} = useTranslation()

  const {changeOpenModalWindow, exportExcel} = useCommon()
  const {setAccountingCategoryTableSettings, deleteAccountingCategoryData} =
    useAccountingCategoryService()
  const search = useSelector(searchValueAccountingCategory)
  const [searchValue, setSearchValue] = useState('')
  const tableSettings = useSelector(accountingCategoryTableSettings)
  const selectedAccountingCategory = useSelector(selectedInTable)
  const accountingCategories = useSelector(
    form.isStoreCategory
      ? storeAccountingCategoriesSelector
      : companyAccountingCategoriesSelector
  )

  const handleSubmitDelete = () => {
    deleteAccountingCategoryData({ids: selectedAccountingCategory})
  }

  const confirmDelete = () => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleSubmitDelete(),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const debouncedValue = useDebounce(searchValue, 500)

  const onClickOpenModalWindow = () => {
    form.resetFields()
    changeOpenModalWindow(true)
  }

  useEffect(() => {
    const debouncedSearchValue = !debouncedValue ? null : debouncedValue
    if (search !== debouncedSearchValue) {
      setAccountingCategoryTableSettings({f_title: debouncedSearchValue})
    }
  }, [debouncedValue])

  const downloadButtonHandler = () => {
    exportExcel({
      tableName: `dictionary/accounting-categories`,
      tableSettings,
    })
  }

  const ActionButton = [
    <SearchAntdComponent
      key={1}
      value={searchValue}
      onChangeText={setSearchValue}
      suffix={<SearchOutlined />}
    />,
    <AddButton onClick={onClickOpenModalWindow} />,
    <DeleteButton
      disabled={isIncludeSelectedFilter(
        accountingCategories,
        selectedAccountingCategory
      )}
      onClick={confirmDelete}
    />,
    <DownloadButton onClick={downloadButtonHandler} />,
  ]
  const item = createItemHeaderMenu(ActionButton)

  return (
    <PageHeaderWithMobileMenu
      title={t('Acc. category')}
      items={item}
      extra={ActionButton}
    />
  )
}

export default AccountingCategoryHeader
