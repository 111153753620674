import {createSlice} from '@reduxjs/toolkit'
import {
  getAccounting,
  setAccounting,
  updateAccounting,
} from '../asyncServices/accountingService'
import {resetReduxStore} from '../resetStore'

export const ACTIVE_ACCOUNTING_COMPANY_TARGET_ID = 0

export const initialState = {
  lastUpdated: null,
  accounting: [],
  summary: [],
  tableSettings: {
    store: {
      s_direction: 'DESC',
      f_toTotalAmount: null,
      f_fromTotalAmount: null,
      f_toCashAmount: null,
      f_fromCashAmount: null,
      f_toCardAmount: null,
      f_fromCardAmount: null,
      f_stores: null,
      f_author: null,
      f_credit: null,
      f_createdDateTo: null,
      f_createdDateFrom: null,
      f_shiftId: null,
      pageSize: 100,
      page: 1,
      s_field: 'createdDate',
      withSummary: true,
      f_accountingCategoryId: null,
    },
    company: {
      s_direction: 'DESC',
      f_toAmount: null,
      f_fromAmount: null,
      f_toCashAmount: null,
      f_fromCashAmount: null,
      f_toCardAmount: null,
      f_fromCardAmount: null,
      f_author: null,
      f_credit: null,
      f_createdDateTo: null,
      f_createdDateFrom: null,
      pageSize: 100,
      page: 1,
      s_field: 'createdDate',
      f_type: null,
      withSummary: true,
      f_accountingCategoryId: null,
    },
    activeAccountingTargetId: ACTIVE_ACCOUNTING_COMPANY_TARGET_ID,
    isStoreAccounting: false,
  },
  isLoading: null,
  totalCount: 0,
}

// prevent default data from mutation
Object.freeze(initialState)

const accountingSlice = createSlice({
  name: 'accountingSlice',
  initialState,
  reducers: {
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
    setCompanyTableSettings: (state, action) => {
      state.tableSettings.company = {
        ...state.tableSettings.company,
        ...action.payload,
      }
    },
    setStoreTableSettings: (state, action) => {
      state.tableSettings.store = {
        ...state.tableSettings.store,
        ...action.payload,
      }
    },
    setActiveAccountingTargetId: (state, action) => {
      state.tableSettings.activeAccountingTargetId = action.payload
      if (action.payload === 0) {
        state.tableSettings.isStoreAccounting = false
      } else {
        state.tableSettings.isStoreAccounting = true
        state.tableSettings.store.f_stores = [action.payload]
      }
    },
    resetAccountingShiftIdFilter: (state) => {
      state.tableSettings.store.f_shiftId = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccounting.pending, (state) => {
      state.isLoading = 'getAccounting'
    })
    builder.addCase(getAccounting.fulfilled, (state, action) => {
      state.accounting = action.payload.accounting
      state.totalCount = action.payload.totalCount
      state.summary = action.payload.summary
      state.isLoading = null
    })
    builder.addCase(updateAccounting.pending, (state) => {
      state.isLoading = 'updateAccounting'
    })
    builder.addCase(updateAccounting.fulfilled, (state) => {
      state.isLoading = null
    })
    builder.addCase(setAccounting.pending, (state) => {
      state.isLoading = 'setAccounting'
    })
    builder.addCase(setAccounting.fulfilled, (state) => {
      state.isLoading = null
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setLastUpdated,
  setCompanyTableSettings,
  setStoreTableSettings,
  setActiveAccountingTargetId,
  resetAccountingShiftIdFilter,
} = accountingSlice.actions
export default accountingSlice.reducer
