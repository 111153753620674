import {createSlice} from '@reduxjs/toolkit'
import {
  deleteSupplies,
  getSupplies,
  getSupply,
  setSupply,
  updateSupply,
} from '../asyncServices/suppliesService'
import {resetReduxStore} from '../resetStore'

const initialState = {
  supplies: [],
  totalCount: 0,
  selectedSupplies: [],
  selectedSupply: null,
  tableSettings: {
    s_direction: 'DESC',
    s_field: 'date',
    f_name: null,
    f_supplierId: null,
    f_unitId: null,
    f_cardPayment: null,
    pageSize: 100,
    page: 1,
  },
  lastUpdated: null,
  isLoading: null,
  selectedId: null,
  selectedIdIngredientHistory: null,
}

const supplySlice = createSlice({
  name: 'supplySlice',
  initialState,
  reducers: {
    setSelectedSupplies: (state, action) => {
      state.selectedSupplies = action.payload
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload
    },
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload.tableSettings,
      }
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSupplies.pending, (state) => {
      state.isLoading = 'getSupplies'
    })
    builder.addCase(getSupplies.fulfilled, (state, action) => {
      state.supplies = action.payload.supplies
      state.totalCount = action.payload.totalCount
      state.isLoading = null
    })
    builder.addCase(getSupply.pending, (state) => {
      state.isLoading = 'getSupply'
    })
    builder.addCase(getSupply.fulfilled, (state, action) => {
      state.selectedSupply = action.payload
      state.isLoading = null
    })
    builder.addCase(setSupply.pending, (state) => {
      state.isLoading = 'setSupply'
    })
    builder.addCase(setSupply.fulfilled, (state, action) => {
      state.isLoading = null
      state.lastUpdated = Date.now()
    })
    builder.addCase(updateSupply.pending, (state) => {
      state.isLoading = 'updateSupply'
    })
    builder.addCase(updateSupply.fulfilled, (state, action) => {
      state.isLoading = null
      state.lastUpdated = Date.now()
    })
    builder.addCase(deleteSupplies.pending, (state) => {
      state.isLoading = 'deleteSupplies'
    })
    builder.addCase(deleteSupplies.fulfilled, (state, action) => {
      state.selectedSupplies = action.payload.selectedSupplies
      state.isLoading = null
      state.lastUpdated = Date.now()
    })

    builder.addCase(resetReduxStore, (state) => {
      state = initialState
    })
  },
})

export const {
  setSelectedSupplies,
  setTableSettings,
  setLastUpdated,
  setRaws,
  setSelectedId,
} = supplySlice.actions
export default supplySlice.reducer
